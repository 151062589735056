import moment from 'moment';
import Vue from 'vue';
import smoothscroll from 'smoothscroll-polyfill';
import { VPopover, VTooltip } from 'v-tooltip';
import Clipboard from 'v-clipboard';
import App from './App.vue';
import router from './router';
import store from './store';
import i18n from './i18n';
import '@/assets/scss/main.scss';
import { getCulture } from './utils';

Vue.config.productionTip = false;

VTooltip.options.popover.defaultBaseClass = 'base-tooltip base-popover';
VTooltip.options.popover.defaultWrapperClass = 'popover-wrapper';

Vue.component('v-popover', VPopover);
Vue.component('Button', () => import('@rr-component-library/button/src/main'));
Vue.component('Row', () => import('@rr-component-library/row/src/main'));
Vue.component('Col', () => import('@rr-component-library/col/src/main'));
Vue.component('Loader', () => import('@rr-component-library/loader/src/main'));
Vue.component('Alert', () => import('@rr-component-library/alert/src/main'));
Vue.component('Link', () => import('@rr-component-library/link/src/main'));
Vue.component('Icon', () => import('@rr-component-library/icon'));
Vue.use(Clipboard);

const lang = getCulture() || 'ru';
Vue.prototype.$lang = lang;
// eslint-disable-next-line no-underscore-dangle
window.__LANGUAGE__ = lang;

Vue.prototype.$reversedEnum = (enumObj) => Object.entries(enumObj || {})
  .reduce((obj, [key, value]) => ({
    ...obj,
    [value]: key,
  }), {});

i18n.locale = lang;
i18n.pluralizationRules.ru = (val) => {
  const cases = [2, 0, 1, 1, 1, 2];
  return (val % 100 > 4 && val % 100 < 20) ? 2 : cases[(val % 10 < 5) ? val % 10 : 5];
};
document.querySelector('html').setAttribute('lang', lang);

Vue.prototype.$moment = moment;
const momentLocal = i18n.locale === 'uz' ? 'uz-latn' : i18n.locale;
moment.locale(momentLocal);

Vue.prototype.$goBack = (defaultBackLink) => {
  let backLink = sessionStorage.getItem('previousPath');
  const resolved = backLink
    ? router.resolve(backLink)
    : null;

  const useDefault = !resolved || !resolved.route.name
    || router.currentRoute.meta?.backLevel < resolved.route.meta?.backLevel;

  if (useDefault) {
    backLink = defaultBackLink || '/';
  }

  if (typeof backLink === 'string' && backLink.indexOf('http') === 0) {
    window.location.href = backLink;
    return;
  }

  router.push(backLink);
};

Vue.mixin({
  beforeRouteEnter(to, from, next) {
    if (from.name) {
      sessionStorage.setItem('previousPath', from.fullPath);
    }
    next();
  },
});

Vue.directive('click-outside', {
  bind(el, binding) {
    const { bubble } = binding.modifiers;
    const handler = (e) => {
      if (bubble || (!el.contains(e.target) && el !== e.target)) {
        binding.value(e);
      }
    };
    // eslint-disable-next-line no-param-reassign,no-underscore-dangle
    el.__vueClickOutside__ = handler;
    document.addEventListener('click', handler);
  },
});

new Vue({
  router,
  store,
  i18n,
  render: (h) => h(App),
}).$mount('#app');

// apiConfig({ store });

let isTouch = false;
if ('ontouchstart' in document.documentElement) {
  isTouch = true;
}
document.body.className += isTouch ? ' touch' : ' no-touch';
smoothscroll.polyfill();
