<template>
  <div class="empty-state">
    <div class="section__group mt-0">
      <div class="empty-state__img" v-if="img">
        <img :src="img" alt="">
      </div>
      <div class="section__item">
        <b class="text-lg" v-if="title" v-html="title"></b>
        <div :class="{'mt-8': title}" v-if="subtitle" v-html="subtitle"></div>
        <slot></slot>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: 'EmptyState',
  props: {
    img: String,
    title: String,
    subtitle: String,
  },
};
</script>

<style lang="scss">
.empty-state {
  text-align: center;
  margin: 0 auto;
  @include v-spacing('padding-top', m);
  @include v-spacing('padding-bottom', m);

  @media (min-width: breakpoint(md)) {
    padding-left: 48px;
    padding-right: 48px;
  }

  &__img {
    padding: 0 48px;
    margin-bottom: 1.5rem;
  }
}
</style>
