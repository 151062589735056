<template>
  <div
    class="filter"
    :class="{
      'filter_opened': isOpened,
      'filter_toggle': toggleable,
    }"
  >
    <div
      class="filter__header"
      v-if="toggleable"
      @click="toggle"
    >
      <icon class="filter__caret" name="down"></icon>
      <div class="filter__title" v-html="name"></div>
    </div>
    <div class="filter__header" v-if="$scopedSlots.header">
      <slot name="header"></slot>
    </div>
    <div class="filter__content">
      <slot></slot>
    </div>
  </div>
</template>

<script>
export default {
  name: 'FilterItem',
  props: {
    id: String,
    state: Object,
    name: String,
    opened: Boolean,
    toggleable: {
      type: Boolean,
      default: true,
    },
  },
  data() {
    return {
      isOpened: this.opened,
    };
  },
  methods: {
    toggle() {
      this.isOpened = !this.isOpened;
      this.saveState();
    },
    saveState() {
      if (!this.id) {
        return;
      }
      let state = localStorage.getItem('eventsFilterState');
      state = state ? JSON.parse(state) : {};
      state[this.id] = this.isOpened;
      localStorage.setItem('eventsFilterState', JSON.stringify(state));
    },
  },
};
</script>

<style lang="scss">
  .filter {
    padding: 16px 0;

    &_toggle {
      padding: 0;
    }

    &__header {
      display: flex;
      align-items: center;
      padding: 0 20px;
      user-select: none;

      .filter_toggle & {
        padding-top: 16px;
        padding-bottom: 16px;
        cursor: pointer;
      }
    }

    &__caret {
      display: block;
      width: 24px;
      height: 24px;
      margin-right: 12px;
      opacity: 0.45;
      flex: 0 0 auto;

      .filter_opened & {
        transform: rotate(180deg);
      }
    }

    &__title {
      @include text-lg;
      font-weight: $font-weight-semibold;
    }

    &__content {
      display: none;
      padding: 0 20px;
      margin-top: 4px;

      .filter_toggle & {
        padding: 0 20px 16px;
        margin-top: 0;
      }

      .filter_opened & {
        display: block;
      }
    }

    &__content-item {
      margin-top: 12px;

      &:first-child {
        margin-top: 0;
      }
    }
  }
</style>
