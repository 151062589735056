<template>
  <div
    class="events-search"
    :class="{
      'events-search_active': isActive,
    }"
  >
    <div
      class="events-search__overlay"
      @click="close"
    ></div>
    <div class="events-search__container">
      <SearchResults
        ref="searchResults"
        v-model="value"
        :isOpened="isOpened"
        :showRecentResults="true"
        :recentResultsStorageKey="'rr_results_event_search'"
        :placeholder="$t('general.search') + '...'"
        @close="close"
        @search="searchEvents"
        @change="cancelSearchEvents"
        @select="selectEvent"
        @select:recent.prevent="(e, result) => selectRecentEvent(result)"
      >
        <template v-slot:field>
          <label>
            <Input
              v-model.trim="value"
              ref="search"
              :placeholder="$t('general.search') + '...'"
              icon-left="search"
              type="search"
              no-border
              mobile
              clearable
              @focus="open"
            ></Input>
          </label>
        </template>
        <template v-slot:resultLeftPart="{ result }">
          <div class="search-result__title">
            <b>{{ result.title }}</b>
          </div>
          <div
            class="search-result__subtitle"
            v-if="result.beginDate"
            v-html="formatDateTime(result.beginDate)"
          >
          </div>
        </template>
      </SearchResults>
    </div>
  </div>
</template>

<script>
import eventsApi from '@/services/events-api';
import SearchResults from '@/components/SearchResults/SearchResults.vue';
import Input from '@rr-component-library/input/src/main';
import { formatDate } from '@/assets/js/helpers';

export default {
  name: 'IndexSearch',
  components: {
    SearchResults,
    Input,
  },
  data() {
    return {
      value: null,
      isActive: false,
      isOpened: false,
    };
  },
  methods: {
    open() {
      const delay = window.innerWidth < 1024 ? 0 : 200;
      this.isActive = true;

      if (!delay) {
        this.isOpened = true;
      } else {
        setTimeout(() => {
          this.isOpened = true;
        }, delay);
      }
    },
    close() {
      this.isActive = false;
      this.isOpened = false;
    },
    searchEvents(value) {
      const payload = {
        searchedValue: value,
        page: {
          skip: 0,
          take: 20,
        },
      };

      eventsApi.searchEvents(payload)
        .then((response) => {
          this.$refs.searchResults.setResults(value, response.data.list || []);
        })
        .catch(() => {
          this.$refs.searchResults.setResults(value, null);
        });
    },
    cancelSearchEvents() {
      eventsApi.cancelRequest('searchEvents');
    },
    selectEvent(event) {
      this.$refs.searchResults.saveToRecent({
        id: event.code,
        title: event.title,
        value: event.title,
        data: {
          code: event.code,
        },
      });
      this.$router.push({
        name: 'Results',
        params: {
          eventCode: event.code,
        },
      });
    },
    selectRecentEvent(event) {
      this.$router.push({
        name: 'Results',
        params: {
          eventCode: event.data.code,
        },
      });
    },
    formatDateTime(dateStr) {
      const date = formatDate([dateStr]);
      const time = new Date(dateStr).toLocaleTimeString(this.$lang, {
        hour: '2-digit',
        minute: '2-digit',
      });
      return `${date}, ${time}`.replace(' г.', '');
    },
  },
  mounted() {
    const input = this.$refs.search.$el.querySelector('input');
    input.addEventListener('focus', (e) => {
      this.$refs.search.$emit('focus', e);
    });
  },
  beforeDestroy() {
    document.body.style.overflow = null;
  },
};
</script>

<style lang="scss" scoped>
@import "./IndexSearch";
</style>
