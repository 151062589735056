import axios from 'axios';
import i18n from '@/i18n';
import api from './api';

const { CancelToken } = axios;
const cancelTokens = {};

export default {
  getEventInfo({ eventId, eventCode }) {
    return api.post('/api/events/get', {
      eventId,
      eventCode,
      language: i18n.locale,
    });
  },
  getRaceInfo({ raceId }) {
    return api.get('/api/results/options/get', {
      params: {
        RaceId: raceId,
        Language: i18n.locale,
      },
    });
  },
  getIndividualResults({
    eventId, raceId, page, filter, isStagesOn, search,
  }) {
    return api.post('/api/results/individual/get', {
      eventId,
      raceId,
      page,
      filter,
      isStagesOn,
      search,
      language: i18n.locale,
    });
  },
  getRelayResults({
    eventId, raceId, page, filter, isStagesOn, search,
  }) {
    return api.post('/api/results/relay/get', {
      eventId,
      raceId,
      page,
      filter,
      isStagesOn,
      search,
      language: i18n.locale,
    });
  },
  getLeaders({
    raceId,
  }) {
    return api.get('/api/results/statistics/leaders', {
      params: {
        RaceId: raceId,
        Language: i18n.locale,
      },
    });
  },
  getEventStatistics({ eventCode }) {
    return api.get('/api/results/statistics/get', {
      params: {
        EventCode: eventCode,
        Language: i18n.locale,
      },
    });
  },
  getComparedIndividualResults({
    raceId, page, ids, isStagesOn,
  }) {
    return api.post('/api/results/individual/compare', {
      raceId,
      page,
      participantsIds: ids,
      isStagesOn,
      language: i18n.locale,
    });
  },
  getComparedRelayResults({
    raceId, page, ids, isStagesOn,
  }) {
    return api.post('/api/results/relay/compare', {
      raceId,
      page,
      teamsIds: ids,
      isStagesOn,
      language: i18n.locale,
    });
  },
  getParticipantProfile({ participantId, eventId }) {
    return api.post('/api/results/individual/profile', {
      participantId,
      eventId,
      language: i18n.locale,
    });
  },
  getTeamProfile({ teamId, eventId }) {
    return api.post('/api/results/relay/profile', {
      teamId,
      eventId,
      language: i18n.locale,
    });
  },
  searchResults({
    searchValue, eventId, raceId, page, pageSize,
  }) {
    return api.post('/api/events/searchParticipant', {
      searchValue,
      eventId,
      raceId,
      page,
      pageSize,
      language: i18n.locale,
    }, {
      cancelToken: new CancelToken(((c) => {
        cancelTokens.searchIndividualResults = c;
      })),
    });
  },
  cancelSearchResults() {
    if (cancelTokens.searchIndividualResults) {
      cancelTokens.searchIndividualResults('canceled');
    }
  },
};
