import cookieHelper from '@/assets/js/cookieHelper';

const config = {
  cookieName: '_rr_culture',
  cookieSeparator: '|',
  culturePrefix: 'c=',
  uiCulturePrefix: 'uic=',
};

function getCulture() {
  const cultureValue = cookieHelper.getCookie(config.cookieName) || '';
  const cultureArr = cultureValue.split(config.cookieSeparator);

  if (cultureArr.length !== 2
    || cultureArr[0].indexOf(config.culturePrefix) !== 0
    || cultureArr[1].indexOf(config.uiCulturePrefix) !== 0
  ) {
    return null;
  }

  return cultureArr[0].slice(2, 4) || null;
}

function setCulture(culture, origin) {
  const path = `${window.location.origin}${window.location.pathname}`;
  const search = encodeURIComponent(window.location.search);
  const returnUrl = `${path}${search}`;
  window.location.href = `${origin}/Culture/SetCulture?culture=${culture}&returnUrl=${returnUrl}&isExternal=true`;
}

export {
  getCulture,
  setCulture,
};
