<template>
  <div
    class="lang-switch"
    v-if="filteredLanguages.length > 1"
  >
    <slot :language="language"></slot>
    <Dropdown
      v-bind="dropdownProps"
    >
      <template slot="items">
        <div
          class="base-dropdown__item"
          v-for="{ id, text, img } in filteredLanguages"
          :key="id"
          v-show="!hideCurrent || id !== locale"
        >
          <a
            class="base-dropdown__link"
            @click.prevent="changeLanguage(id)"
          >
            <span v-if="isFlags" class="lang-switch__flag base-dropdown__icon">
              <img :src="img" :alt="id">
            </span>
            <span class="base-dropdown__text">{{ text }}</span>
          </a>
        </div>
      </template>
    </Dropdown>
  </div>
</template>

<script>
import Dropdown from '@/components/Dropdown/Dropdown.vue';
import ruImg from './img/ru.jpg';
import enImg from './img/en.jpg';
import uzImg from './img/uz.jpg';

export default {
  name: 'LangSwitch',
  props: {
    locale: String,
    locales: {
      type: Array,
      default: () => [],
    },
    dropdownOptions: {
      type: Object,
      default: () => ({}),
    },
    hideCurrent: Boolean,
    isFlags: {
      type: Boolean,
      default: true,
    },
  },
  components: {
    Dropdown,
  },
  data() {
    return {
      languages: [
        {
          id: 'ru',
          text: 'Русский',
          img: ruImg,
        },
        {
          id: 'en',
          text: 'English',
          img: enImg,
        },
        {
          id: 'uz',
          text: 'O\'zbek',
          img: uzImg,
        },
      ],
      currentLang: this.locale,
    };
  },
  computed: {
    filteredLanguages() {
      return this.languages.filter((lang) => this.locales.includes(lang.id) && lang.id !== this.currentLang);
    },
    language() {
      return this.languages.find((lang) => lang.id === this.locale) || {};
    },
    dropdownProps() {
      return {
        triggerId: null,
        listeners: 'hover click',
        placement: 'top-end',
        hideOverlay: true,
        autoWidth: true,
        ...this.dropdownOptions,
      };
    },
  },
  methods: {
    changeLanguage(id) {
      this.$emit('change', id);
    },
  },
};
</script>

<style lang="scss" scoped>
@import "./LangSwitch";
</style>
