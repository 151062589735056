/* eslint no-shadow: ["error", { "allow": ["state", "getters"] }] */
import resultsApi from '@/services/results-api';
import disciplines from '@/assets/js/disciplines';

const state = {
  event: {},
  racesById: {},
};

const getters = {
  event: (state) => state.event,
  raceInfo: (state) => (id) => state.racesById[id],
};

const mutations = {
  setEvent(state, event) {
    state.event = event || {};
  },
  setRaceInfo(state, {
    id,
    data,
  }) {
    const race = (state.event.races || []).find((o) => o.id === id) || {};
    state.racesById[id] = {
      ...data,
      calcMethod: race.resultsCalculationMethod,
      calcMethodByCategory: race.resultsCalculationMethodByCategory,
      isRelay: race.isRelay,
      isComplex: race.isComplex,
      disciplineIcon: disciplines[data.disciplineCode]?.icon || null,
    };
  },
};

const actions = {
  getEvent({ state, commit, dispatch }, { eventIdOrCode }) {
    if (state.event.id === eventIdOrCode || state.event.code === eventIdOrCode) {
      return true;
    }

    return resultsApi.getEventInfo({
      eventCode: eventIdOrCode,
    })
      .then((response) => {
        const event = response.data || {};
        event.races.forEach((race, i) => {
          event.races[i].disciplineIcon = disciplines[race.disciplineCode]?.icon || null;
          dispatch('follow/updateRaceFollowList', {
            eventCode: event.code,
            raceId: race.id,
          }, {
            root: true,
          });
        });
        commit('setEvent', event);
      })
      .catch(() => {
        commit('setEvent', null);
      });
  },
  getRaceInfo({ state, commit }, { raceId }) {
    if (state.racesById[raceId]) {
      return true;
    }

    return resultsApi.getRaceInfo({
      raceId,
    })
      .then((response) => {
        commit('setRaceInfo', {
          id: raceId,
          data: response.data,
        });
      })
      .catch(() => {});
  },
};

export default {
  namespaced: true,
  state,
  mutations,
  getters,
  actions,
};
