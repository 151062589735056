<template>
  <section class="page-not-found section_pt-lg section_pb-lg">
    <div class="container">
      <div class="page-not-found__content">
        <img class="page-not-found__img" :src="themeImages['404-error']" alt="404">
        <h1 v-html="$t('page_not_found.title')"></h1>
      </div>
    </div>
  </section>
</template>

<script>
import { mapGetters } from 'vuex';

export default {
  name: 'PageNotFound',
  computed: {
    ...mapGetters('settings', ['themeImages']),
  },
};
</script>

<style lang="scss" scoped>
.page-not-found {
  flex-grow: 1;
  display: flex;
  align-items: center;
  justify-content: center;
  text-align: center;
  background-color: $color-white;

  &__img {
    display: block;
    margin: 0 auto 1rem;
  }
}
</style>
