const ResultRoundingRule = Object.freeze({
  Default: 0,
  Up: 1,
  Down: 2,
  HundredthsUp: 3,
  HundredthsDown: 4,
  TenthsUp: 5,
  TenthsDown: 6,
  Thousandths: 7,
});

export default ResultRoundingRule;
