<template>
  <div
    class="badge"
    :class="classes"
    @click="$emit('click')"
  >
    <div
      class="badge__icon"
      v-if="icon"
    >
      <icon :icon-name="icon"></icon>
    </div><div
      class="badge__text"
      v-html="text"
    ></div>
  </div>
</template>

<script>
export default {
  name: 'Badge',
  props: {
    type: String,
    icon: String,
    text: [String, Number],
    size: String,
  },
  computed: {
    classes() {
      const classes = [];
      const prefix = 'badge_';
      if (this.type) {
        classes.push(prefix + this.type);
      }
      if (this.size) {
        classes.push(prefix + this.size);
      }
      return classes;
    },
  },
};
</script>

<style lang="scss">
  .badge {
    display: inline-block;
    vertical-align: middle;
    flex: 0 0 auto;
    white-space: nowrap;
    @include text-xs;
    background-color: $color-primary;
    color: $color-white;
    font-weight: $font-weight-semibold;
    line-height: 1;
    min-width: 24px;
    border-radius: 2px;
    padding: 0 6px;
    text-align: center;
    z-index: 2;
    transition: $transition-default;
    transition-property: color;
    cursor: default;

    &_sm {
      font-size: 10px;
      min-width: 16px;
      padding: 0 4px;
    }

    &_md {
      @include text-sm;
      padding: 5px 8px;
    }

    &_lg {
      @include text-lg;
      min-width: 30px;
    }

    &_pos_left,
    &_pos_right {
      position: absolute;
      top: 0;
      margin: 8px 0;
    }

    &_pos_left {
      left: 0;
      border-top-left-radius: 0;
      border-bottom-left-radius: 0;
    }

    &_pos_right {
      right: 0;
      border-top-right-radius: 0;
      border-bottom-right-radius: 0;
    }

    &_rounded {
      border-radius: 2px;
    }

    &_circle {
      border-radius: 99px;
    }

    &__icon, &__text {
      display: inline-block;
      vertical-align: middle;
      line-height: 24px;

      .badge_sm & {
        line-height: 16px;
      }

      .badge_md & {
        line-height: 24px;
      }

      .badge_lg & {
        line-height: 30px;
      }
    }

    &__icon {
      flex: 0 0 auto;
      position: relative;
      margin-right: 4px;

      .svg-icon {
        display: block;
        width: 16px;
        height: 16px;
      }
    }

    &_premium {
      background-color: $color-black;
      color: $color-white;
    }

    &_sale {
      background-color: $color-danger-5;
      color: $color-white;
    }

    &_danger {
      background-color: $color-danger;
      color: $color-white;
    }

    &_warning {
      background-color: $color-warning-2;
      color: $color-warning-8;
    }

    &_muted {
      background-color: $color-black-4;
      color: $color-black-45;
    }

    &_preset {
      background-color: $color-primary-1;
      border: 1px solid $color-primary-3;
      color: $color-primary;
      cursor: pointer;
      user-select: none;

      .no-touch &:hover {
        color: $color-primary-5;
      }
    }

    &_light {
      background-color: $color-white;
      box-shadow: $elevation-deth-4;
      color: $color-black;
    }

    &_text {
      display: inline-flex;
      text-align: left;
      white-space: normal;
      align-items: flex-start;

      .badge__icon {
        top: -1px;

        .svg-icon {
          display: inline-block;
        }
      }
    }

    &_primary {
      background-color: $color-primary-2;
      color: $color-primary;
    }
  }

  .badges {
    display: flex;
    flex-wrap: wrap;
    align-items: center;
    margin-top: -8px;
    margin-right: -8px;

    &_column {
      flex-direction: column;
      align-items: stretch;
    }

    &__item {
      flex: 0 0 auto;
      margin-top: 8px;
      margin-right: 8px;
    }
  }
</style>
