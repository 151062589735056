export default {
  run: {
    icon: 'running',
    img: 'Content/redesign/pages/events/images/event-descipline/run-min.jpg',
    imgLg: 'Content/redesign/pages/events/images/event-descipline/run.jpg',
  },
  'run-relay': {
    icon: 'running',
  },
  walk: {
    icon: 'walking',
  },
  'ski-race': {
    icon: 'cross-country-skiing',
    img: 'Content/redesign/pages/events/images/event-descipline/ski-race-min.jpg',
    imgLg: 'Content/redesign/pages/events/images/event-descipline/ski-race.jpg',
  },
  'ski-race-relay': {
    icon: 'cross-country-skiing',
    img: 'Content/redesign/pages/events/images/event-descipline/ski-race-min.jpg',
    imgLg: 'Content/redesign/pages/events/images/event-descipline/ski-race.jpg',
  },
  swimming: {
    icon: 'swimming',
    img: 'Content/redesign/pages/events/images/event-descipline/swim-min.jpg',
    imgLg: 'Content/redesign/pages/events/images/event-descipline/swim.jpg',
  },
  'swimming-team': {
    icon: 'swimming',
    img: 'Content/redesign/pages/events/images/event-descipline/swim-min.jpg',
    imgLg: 'Content/redesign/pages/events/images/event-descipline/swim.jpg',
  },
  cycling: {
    icon: 'cycling',
    img: 'Content/redesign/pages/events/images/event-descipline/cycle-min.jpg',
    imgLg: 'Content/redesign/pages/events/images/event-descipline/cycle.jpg',
  },
  snowboard: {
    icon: 'snowboarding',
    img: 'Content/redesign/pages/events/images/event-descipline/snowboard-min.jpg',
    imgLg: 'Content/redesign/pages/events/images/event-descipline/snowboard.jpg',
  },
  triathlon: {
    icon: 'triathlon',
    img: 'Content/redesign/pages/events/images/event-descipline/triathlon-min.jpg',
    imgLg: 'Content/redesign/pages/events/images/event-descipline/triathlon.jpg',
  },
  'triathlon-relay': {
    icon: 'triathlon',
    img: 'Content/redesign/pages/events/images/event-descipline/triathlon-min.jpg',
    imgLg: 'Content/redesign/pages/events/images/event-descipline/triathlon.jpg',
  },
  duathlon: {
    icon: 'duathlon',
  },
  'duathlon-relay': {
    icon: 'duathlon',
  },
  obstacleracing: {
    icon: 'track-and-field',
    img: 'Content/redesign/pages/events/images/event-descipline/obstacleracing-min.jpg',
    imgLg: 'Content/redesign/pages/events/images/event-descipline/obstacleracing.jpg',
  },
  skiing: {
    icon: 'skiing',
    img: 'Content/redesign/pages/events/images/event-descipline/skiing-min.jpg',
    imgLg: 'Content/redesign/pages/events/images/event-descipline/skiing.jpg',
  },
  'sledge-sports': {
    icon: 'dog-paw',
    img: 'Content/redesign/pages/events/images/event-descipline/sledge-sports-min.jpg',
    imgLg: 'Content/redesign/pages/events/images/event-descipline/sledge-sports.jpg',
  },
  trail: {
    icon: 'running',
  },
  biathlon: {
    icon: 'aquathlon',
  },
  'biathlon-relay': {
    icon: 'aquathlon',
  },
  'm-football': {
    icon: 'soccer',
  },
  tennis: {
    icon: 'tennis',
  },
  'tennis-team': {
    icon: 'tennis',
  },
  'beach-voll': {
    icon: 'beach-volleyball',
  },
  'beach-tenn': {
    icon: 'tennis',
  },
  'table-tenn': {
    icon: 'table-tennis',
  },
  'table-tenn-team': {
    icon: 'table-tennis',
  },
  sailing: {
    icon: 'windsurfing',
  },
  backetball: {
    icon: 'basketball',
  },
  'backetball-team': {
    icon: 'basketball',
  },
  'team-relay': {
    icon: 'team',
  },
  mountaineering: {
    icon: 'mountain',
  },
  'ski-biathlon': {
    icon: 'biathlon',
    img: 'Content/redesign/pages/events/images/event-descipline/ski-race-min.jpg',
    imgLg: 'Content/redesign/pages/events/images/event-descipline/ski-race.jpg',
  },
  'rock-climbing': {
    icon: 'climbing',
  },
  orienteering: {
    icon: 'compass',
  },
  speedskating: {
    icon: 'speedscating',
  },
  boxing: {
    icon: 'boxing',
  },
};
