<template>
  <SearchResults
    v-model="value"
    ref="searchResults"
    class="broadcast-search"
    :class="{'broadcast-search_opened': isOpened}"
    :minLength="1"
    :placeholder="$t('participant_search.placeholder')"
    :isOpened="isOpened"
    @search="searchResults"
    @change="cancelSearchResults"
    @select="findResult"
    @close="close"
  >
    <template v-slot:field>
      <label class="broadcast-search__field">
        <Input
          ref="search"
          v-model.trim="value"
          :placeholder="$t('participant_search.placeholder')"
          :icon-right="!value ? 'search' : ''"
          type="search"
          no-border
          mobile
          clearable
          @focus="open"
        ></Input>
      </label>
    </template>
    <template v-slot:resultLeftPart="{ result }">
      <div class="search-result__title" v-html="getTitle(result)"></div>
      <div class="search-result__subtitle">
        <div class="text-with-icon text-nowrap">
          <Icon
            class="icon color-muted"
            :name="getRaceIcon(result.raceId)"
            size="s"
          ></Icon>&nbsp;&nbsp;<span>{{ result.raceName }}</span>
        </div>
      </div>
    </template>
    <template v-slot:tipPlaceholder>
      <div
        class="text-center"
        v-html="$t('participant_search.enter_tip')"
      ></div>
    </template>
    <template v-slot:tipNotFound>
      <b v-html="$t('participant_search.not_found_title')"></b><br>
      <div
        class="text-sm mt-4"
        v-html="$t('participant_search.not_found_text')"
      ></div>
    </template>
  </SearchResults>
</template>

<script>
import resultsApi from '@/services/results-api';
import { markQueryInString } from '@/assets/js/helpers';
import Input from '@rr-component-library/input/src/main';
import SearchResults from '@/components/SearchResults/SearchResults.vue';

export default {
  name: 'EventSearch',
  components: {
    Input,
    SearchResults,
  },
  props: {
    eventId: String,
    raceId: String,
    races: {
      type: Array,
      default: () => [],
    },
  },
  data() {
    return {
      value: null,
      pageSize: 25,
      isOpened: false,
    };
  },
  computed: {
    enoughLength() {
      return this.value && this.value.length >= 3;
    },
  },
  methods: {
    getTitle(result) {
      const title = result.startNumber
        ? `${result.fullName} (№&nbsp;${result.startNumber})`
        : result.fullName;

      return markQueryInString(title, this.value);
    },
    open() {
      this.isOpened = true;
    },
    close() {
      this.isOpened = false;
    },
    getRaceIcon(raceId) {
      const race = this.races.find((o) => o.id === raceId);
      return race ? race.disciplineIcon : null;
    },
    setPageSize(pageSize) {
      this.pageSize = pageSize;
      this.value = null;
      this.$refs.searchResults.setResults(null, null);
    },
    findResult(result) {
      this.close();
      this.$emit('findResult', {
        id: result.participantId,
        raceCode: result.raceCode,
        page: result.page,
        pageSize: this.savedPageSize,
      });
    },
    searchResults(value) {
      const payload = {
        searchValue: value,
        eventId: this.eventId,
        raceId: this.raceId,
        page: {
          skip: 0,
          take: 5,
        },
        pageSize: this.pageSize,
      };

      resultsApi.searchResults(payload)
        .then((response) => {
          const { data } = response;
          this.$refs.searchResults.setResults(value, data.participants || []);
        })
        .catch(() => {
          this.$refs.searchResults.setResults(value, null);
        });
    },
    cancelSearchResults() {
      resultsApi.cancelSearchResults();
    },
  },
  watch: {
    raceId() {
      if (this.$refs.searchResults) {
        this.$refs.searchResults.reset();
      }
    },
  },
  beforeDestroy() {
    this.close();
  },
  mounted() {
    const input = this.$refs.search.$el.querySelector('input');
    input.addEventListener('focus', (e) => {
      this.$refs.search.$emit('focus', e);
    });
  },
};
</script>

<style lang="scss" scoped>
@import "./EventSearch";
</style>
