const ResultStatus = Object.freeze({
  Finish: 0,
  DNF: 1,
  DSQ: 2,
  DNS: 3,
  NC: 4,
  LAP: 5,
});

export default ResultStatus;
