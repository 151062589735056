<template>
  <div
    class="mobile-modal"
    :class="{'mobile-modal_active': isActive || alwaysOpen}"
  >
    <div class="mobile-modal__header">
      <Row class="mobile-modal__header-grid rr-grid_8" align="center">
        <Col size="auto">
          <Button
            class="mobile-modal__close"
            variant="clear"
            shape="square"
            :icon-left="backIcon ? 'left' : 'close'"
            @click="cancel"
          ></Button>
        </Col>
        <Col class="mobile-modal__name" size="stretch">
          <b class="mobile-modal__name-text" v-html="name"></b>
          <template v-if="count">
            &nbsp;&nbsp;
            <Badge
              class="badge_circle"
              :text="count"
              type="danger"
              size="sm"
            ></Badge>
          </template>
        </Col>
        <Col
          size="auto"
          v-if="showResetBtn"
        >
          <Button
            variant="secondary"
            size="sm"
            @click="$emit('reset')"
            :text="$t('filters.clear')"
          ></Button>
        </Col>
      </Row>
    </div>
    <div class="mobile-modal__body">
      <slot></slot>
    </div>
    <div class="mobile-modal__footer" v-show="!hideFooter">
      <Button
        variant="primary"
        wide
        :text="applyText"
        @click="apply"
      ></Button>
    </div>
  </div>
</template>

<script>
import Badge from '@/components/Badge.vue';

export default {
  name: 'MobileModal',
  components: {
    Badge,
  },
  props: {
    name: String,
    count: [String, Number],
    backIcon: Boolean,
    alwaysOpen: Boolean,
    showResetBtn: Boolean,
    applyText: {
      type: String,
      default() {
        return this.$t('filters.apply');
      },
    },
    hideFooter: Boolean,
  },
  data() {
    return {
      isActive: false,
    };
  },
  methods: {
    open() {
      this.isActive = true;
      setTimeout(() => {
        this.$el.scrollTo(0, 0);
      });
    },
    close() {
      this.$emit('close');
      this.isActive = false;
    },
    cancel() {
      this.close();
      this.$emit('cancel');
    },
    apply() {
      this.$emit('apply');
      this.close();
    },
  },
};
</script>

<style lang="scss" scoped>
  .mobile-modal {
    display: none;
    position: fixed;
    z-index: 99;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: $color-white;
    overflow-x: hidden;
    padding-top: 56px;
    padding-bottom: 64px;

    &_active {
      display: grid;
    }

    &__header {
      position: fixed;
      z-index: 10;
      top: 0;
      left: 0;
      width: 100%;
      background-color: $color-white;
      box-shadow: $elevation-deth-4;
    }

    &__header-grid {
      flex-wrap: nowrap;
      height: 56px;
      padding: 0 16px;
    }

    &__close {
      margin-left: -10px;
      min-width: 40px;

      &::after {
        content: none;
      }
    }

    &__name {
      display: flex;
      align-items: center;
      min-width: 0;
      overflow: hidden;
      text-overflow: ellipsis;
      white-space: nowrap;
    }

    &__name-text {
      overflow: hidden;
      text-overflow: ellipsis;
      white-space: nowrap;
      flex: 0 1 auto;
    }

    &__body {
      padding: 16px;
    }

    &__body-item {
      margin-top: 16px;

      &:first-child {
        margin-top: 0;
      }

      &_center {
        flex-grow: 1;
        display: flex;
        align-items: center;
        justify-content: center;
        padding: 12px 20px;
      }
    }

    &__text-center {
      text-align: center;
      font-weight: 400;
      font-size: 16px;
      line-height: 24px;
      color: $color-black-85;
    }

    &__full {
      margin-left: -16px;
      margin-right: -16px;
      min-height: 100%;
      display: flex;
      flex-direction: column;
    }

    &__footer {
      display: flex;
      align-items: center;
      padding: 0 16px;
      height: 64px;
      position: fixed;
      z-index: 10;
      bottom: 0;
      left: 0;
      width: 100%;
      background-color: $color-white;
      border-top: 1px solid $color-black-9;
    }

    &__input {
      display: block;
      &-el {
        display: block;
        width: 100%;
        max-width: 100%;
        font-family: $font-family-base;
        font-size: 16px;
        font-weight: normal;
        line-height: 24/16*1em;
        height: 40px;
        padding: 0 20px 16px;
        background: none;
        border: 0;
        border-bottom: 1px solid $color-black-15;
        box-shadow: none;
        @include placeholder {
          font-size: 16px;
          font-family: $font-family-base;
          color: $color-black-25;
          opacity: 1;
        }
      }
    }
  }
</style>
