<template>
  <component :is="currentTenant" />
</template>

<script>
import { mapGetters } from 'vuex';
import CompactFooter from '@/sections/Footer/Footer.vue';

export default {
  name: 'TenantFooter',
  components: {
    CompactFooter,
  },
  computed: {
    ...mapGetters('settings', ['theme', 'themeSettings']),
    showFullFooter() {
      return !this.themeSettings.noFullFooter
        && this.$route.meta.footerType === 'full';
    },
    currentTenant() {
      const tenants = {
        rr: () => import('@/components/Footer/Footer.vue'),
        rzd: () => import('@/components/_rzd/Footer/Footer.vue'),
        bfla: () => import('@/components/_bfla/Footer/Footer.vue'),
        samarkand: () => import('@/components/_samarkand/Footer/Footer.vue'),
        pionercup: () => import('@/components/_pionercup/Footer/Footer.vue'),
        legkovrace: () => import('@/components/_legkovrace/Footer.vue'),
        tulamarathon: () => import('@/components/_tula/Footer.vue'),
      };

      return tenants[this.theme] ?? CompactFooter;
    },
  },
};
</script>
