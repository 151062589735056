import axios from 'axios';
import i18n from '@/i18n';
import api from './api';

const { CancelToken } = axios;
const cancelTokens = {};

export default {
  cancelRequest(key) {
    if (cancelTokens[key]) {
      cancelTokens[key]('canceled');
    }
  },
  getEventsFilters() {
    return api.get('/api/events/listFilter', {
      params: {
        language: i18n.locale,
      },
    });
  },
  getEvents(params) {
    return api.post('/api/events/list', {
      ...params,
      language: i18n.locale,
    }, {
      cancelToken: new CancelToken(((c) => {
        cancelTokens.getEvents = c;
      })),
    });
  },
  searchEvents({
    searchedValue,
    page,
  }) {
    return api.post('/api/events/searchEvent', {
      searchedValue,
      page,
      language: i18n.locale,
    }, {
      cancelToken: new CancelToken(((c) => {
        cancelTokens.searchEvents = c;
      })),
    });
  },
  getEventDetails({ eventCode }) {
    return api.post('/api/events/detailedEvent', {
      eventCode,
      language: i18n.locale,
    });
  },
  getEventMembers({
    eventCode,
    raceCode,
    search,
    page,
  }) {
    return api.post('/api/events/raceMembers', {
      eventCode,
      raceCode,
      language: i18n.locale,
      search,
      page,
    });
  },
  findPlaces(searchString) {
    return api.get(`/api/events/findPlaces?language=${i18n.locale}&searchString=${searchString}`);
  },
  getLiveCount() {
    return api.get('/api/live/live-count');
  },
};
