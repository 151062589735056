<template>
  <router-link
    class="event-card"
    :to="{ name: 'Results', params: { eventCode: code }}"
  >
    <div class="event-card__container">
      <div class="event-card__cover">
        <div
          class="event-card__cover-img"
          v-show="image"
          :style="`background-image: url(${image})`"
        ></div>
        <EventBadges
          v-if="showBadges"
          class="event-card__badges"
          v-bind="badges"
        ></EventBadges>
      </div>
      <div class="event-card__info">
        <div class="event-card__header">
          <div class="event-card__date" v-html="formattedDate"></div>
        </div>
        <div class="event-card__body">
          <div
            class="event-card__name"
            :title="title"
          >
            {{ title }}
          </div>
        </div>
        <div class="event-card__footer">
          <div class="event-card__location text-with-icon text-with-icon_flex">
            <Icon
              class="color-muted"
              name="environment"
              size="s"
            ></Icon>&nbsp;
            <span
              class="event-card__location-text"
              :title="location"
            >
              {{ location }}
            </span>
          </div>
        </div>
      </div>
    </div>
  </router-link>
</template>

<script>
import { mapGetters } from 'vuex';
import { getDiscipline } from '@/utils';
import { formatDate } from '@/assets/js/helpers';
import EventBadges from '@/components/EventBadges/EventBadges.vue';

export default {
  name: 'ResultCard',
  components: {
    EventBadges,
  },
  props: {
    code: String,
    title: String,
    date: {
      type: Array,
      default: () => [],
    },
    location: String,
    img: String,
    disciplineCode: String,
    badges: {
      type: Object,
      default: () => ({}),
    },
  },
  data() {
    return {
      image: null,
    };
  },
  computed: {
    ...mapGetters('settings', ['themeUrls']),
    showBadges() {
      return Object.keys(this.badges)
        .some((key) => !!this.badges[key]);
    },
    formattedDate() {
      return formatDate(this.date);
    },
  },
  methods: {
    setImage() {
      const eventImg = this.img && this.img.replace('-x-', '-x400');
      let disciplineImg = getDiscipline(this.disciplineCode)?.img
        || getDiscipline('run')?.img;
      disciplineImg = `${this.themeUrls.Events}/${disciplineImg}`;

      const img = new Image();

      img.onload = () => {
        this.image = img.src;
      };

      img.onerror = () => {
        img.onerror = null;
        img.src = disciplineImg;
      };

      img.src = eventImg || disciplineImg;
    },
  },
  mounted() {
    this.setImage();
  },
};
</script>

<style lang="scss" scoped>
@import "./ResultCard";
</style>
