const prodConfig = {
  Main: 'https://russiarunning.com',
  Events: 'https://russiarunning.com',
  Diploma: 'https://media.russiarunning.com',
  Clubs: 'https://clubs.russiarunning.com',
  Championships: 'https://champs.russiarunning.com',
  Media: 'https://mysport.photo/',
  Live: 'https://live.russiarunning.com',
  Results: '/',
};

const preprodConfig = {
  Main: 'https://rr-dev.ru',
  Events: 'https://rr-dev.ru',
  Diploma: 'https://media.rr-dev.ru',
  Clubs: 'https://clubs.rr-dev.ru',
  Championships: 'https://champs.rr-dev.ru',
  Media: 'https://medianew.rr-dev.ru',
  Live: 'https://live.rr-dev.ru',
  Results: '/',
};

const testConfig = {
  Main: 'https://test.rr-dev.ru/',
  Events: 'https://test.rr-dev.ru/',
  Diploma: 'https://media.test.rr-dev.ru',
  Clubs: 'https://clubs.test.rr-dev.ru/',
  Championships: 'https://champs.test.rr-dev.ru/',
  Media: 'https://medianew.test.rr-dev.ru',
  Live: 'https://live-test.rr-dev.ru/',
  Results: '/',
};

export function getConfigType() {
  if (window.location.hostname.includes('localhost')) {
    return 'localhost';
  }
  if (window.location.hostname.includes('test.rr-dev')) {
    return 'test';
  }
  if (window.location.hostname.includes('rr-dev')) {
    return 'preprod';
  }
  return 'prod';
}

export function getConfig(type) {
  switch (type) {
    case 'localhost':
    case 'preprod':
      return preprodConfig;

    case 'test':
      return testConfig;

    default:
      return prodConfig;
  }
}
