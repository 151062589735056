<template>
  <main class="layout__main">
    <section class="events-list section_pt section_pb">
      <div class="container">
        <Banner
          v-if="theme === 'rr'"
          :content="bannerTop"
          :contentMobile="bannerTopMobile"
          style="margin-bottom: 24px;"
        ></Banner>
        <div ref="topOfList"></div>
        <Row>
          <Col class="events-list__main" :size="{lg: 'stretch'}">
            <!-- Header : BEGIN -->
            <Row class="section__item" align="center">
              <Col :size="{lg: 'stretch'}">
                <h1 class="h3"><span v-html="$t('results.title')"></span>&nbsp;<span class="subtitle-2 color-muted" v-show="pagination.totalItems > 0">({{ pagination.totalItems }})</span></h1>
                <div class="mb-16 d-lg-none"></div>
              </Col>
              <Col :size="{default: 'stretch', lg: 'auto'}">
                <Search></Search>
              </Col>
              <Col size="auto" class="d-lg-none">
                <div class="events-list__filters-button">
                  <Button
                    class="d-none d-md-block"
                    variant="secondary"
                    :text="$t('filters.title')"
                    icon-left="filter"
                    mobile
                    @click="$refs.filters.open()"
                  ></Button>
                  <Button
                    class="d-md-none"
                    variant="secondary"
                    shape="square"
                    icon-left="filter"
                    mobile
                    @click="$refs.filters.open()"
                  ></Button>
                   <Badge
                     class="badge_circle badge_pos_right"
                     :text="filtersCount"
                     type="danger"
                     size="sm"
                     v-show="filtersCount"
                   ></Badge>
                </div>
              </Col>
            </Row>
            <!-- Header : END -->
            <!-- List : BEGIN -->
            <Loader
              v-if="isLoading"
            ></Loader>
            <Row class="section__item" vertical-gutters v-else>
              <EmptyState
                v-if="!events.length"
                :subtitle="$t('results.noResults')"
              ></EmptyState>
              <template
                v-for="(event, i) in events"
              >
                <Col
                  :key="event.id"
                >
                  <EventCard
                    :code="event.code"
                    :title="event.title"
                    :date="[event.beginDate, event.endDate]"
                    :location="event.place"
                    :img="event.imageUrl"
                    :disciplineCode="event.disciplineCode"
                    :badges="getBadges(event)"
                  ></EventCard>
                </Col>
                <Col
                  :key="'banner-' + i"
                  v-if="theme === 'rr' && (i - 1) % pagination.pageSize === 0"
                >
                  <Banner
                    :content="bannerContent"
                    :contentMobile="bannerContentMobile"
                  ></Banner>
                </Col>
              </template>
            </Row>
            <!-- List : END -->
            <!-- Pagination : BEGIN -->
            <Loader
              v-show="isLoadingMore"
            ></Loader>
            <div
              class="section__item"
              v-if="pagination.totalItems > pagination.pageSize"
              v-show="!isLoading && !isLoadingMore"
            >
              <div
                class="section__item"
                v-show="pagination.page < pagination.totalPages"
              >
                <Button
                  variant="secondary"
                  size="lg"
                  :text="$t('general.show_more')"
                  wide
                  @click="loadMore"
                ></Button>
              </div>
              <div class="section__item">
                <Pagination
                  :page="pagination.page - 1"
                  :totalItems="pagination.totalItems"
                  :itemsPerPage="pagination.pageSize"
                  @change="changePage($event + 1)"
                ></Pagination>
              </div>
            </div>
            <!-- Pagination : END -->
          </Col>
          <!-- Filters : BEGIN -->
          <Col :size="{lg: 'auto'}" class="d-lg-block">
            <Filters
              ref="filters"
              @change="onFiltersChange"
              @filtersCountChanged="filtersCount = $event"
            ></Filters>
            <GeneralPartnerLogo
              v-if="theme === 'rzd'"
              class="events-list__partner-block" />
          </Col>
          <!-- Filters : END -->
        </Row>
      </div>
    </section>
  </main>
</template>

<script>
import { mapGetters } from 'vuex';
import eventsApi from '@/services/events-api';
import Pagination from '@/components/Pagination/Pagination.vue';
import EmptyState from '@/components/EmptyState/EmptyState.vue';
import Badge from '@/components/Badge.vue';
import Banner from '@/components/Banner.vue';
import GeneralPartnerLogo from '@/components/_rzd/GeneralPartnerLogo/GeneralPartnerLogo.vue';
import Filters from './IndexFilters/IndexFilters.vue';
import Search from './IndexSearch/IndexSearch.vue';
import EventCard from './ResultCard/ResultCard.vue';

export default {
  name: 'Index',
  components: {
    Filters,
    Search,
    EventCard,
    Pagination,
    EmptyState,
    Badge,
    Banner,
    GeneralPartnerLogo,
  },
  data() {
    return {
      events: [],
      pagination: {
        page: 1,
        pageSize: 12,
        totalItems: 0,
        totalPages: 0,
      },
      filtersCount: 0,
      isLoading: false,
      isLoadingMore: false,
      bannerTop: '<div class="ad-pic" data-base-url="https://mkt.russiarunning.com/api/v1/48741db6-9461-4d1e-8200-94c7e4d382ff" data-device="desktop"></div>',
      bannerTopMobile: '<div class="ad-pic" data-base-url="https://mkt.russiarunning.com/api/v1/48741db6-9461-4d1e-8200-94c7e4d382ff" data-device="mobile"></div>',
      bannerContent: '<div class="ad-pic" data-base-url="https://mkt.russiarunning.com/api/v1/406f33ca-2f4c-447f-a839-fc1f8e1b4865" data-device="desktop"></div>',
      bannerContentMobile: '<div class="ad-pic" data-base-url="https://mkt.russiarunning.com/api/v1/406f33ca-2f4c-447f-a839-fc1f8e1b4865" data-device="mobile"></div>',
    };
  },
  computed: {
    ...mapGetters('settings', ['theme']),
  },
  methods: {
    getBadges(event) {
      return {
        isRR: event.intoRayRussiaRunnung,
        isSportmaster: event.inSportmasterChampionship,
        isNbd: event.inNrm,
        starRating: event.starRating, // TODO : hw
        approvedStarRating: !event.hideApprovedRating ? event.approvedStarRating : null,
        voteRating: !event.hideMemberRating
          ? event.participantRatingApproved && Math.round(+event.participantRatingApproved * 10) / 10
          : null,
      };
    },
    loadMore() {
      this.pagination.page += 1;
      this.isLoadingMore = true;
      this.onParamsChange();
    },
    changePage(page) {
      this.pagination.page = page;
      this.onParamsChange();
    },
    onParamsChange() {
      this.setQuery();
      this.loadEvents();
    },
    setQuery() {
      const query = {
        ...this.$route.query,
        p: this.pagination.page > 1 ? this.pagination.page : undefined,
      };

      this.$router.replace({ query })
        .catch(() => {});
    },
    parseQuery() {
      const { query } = this.$route;

      this.pagination.page = +query.p > 0 ? +query.p : 1;
    },
    loadEvents() {
      if (!this.isLoadingMore) {
        this.isLoading = true;
        this.events = [];
        this.scrollToTop();
      }

      const filters = this.$refs.filters.getValues();

      eventsApi.getEvents({
        page: {
          skip: (this.pagination.page - 1) * this.pagination.pageSize,
          take: this.pagination.pageSize,
        },
        filter: {
          cityCode: filters.location,
          place: filters.place,
          dateFrom: filters.dateFrom && `${filters.dateFrom}T00:00:00.000Z`,
          dateTo: filters.dateTo && `${filters.dateTo}T23:59:00.000Z`,
          disciplinesCodes: filters.discipline ? [filters.discipline] : null,
          starRatings: filters.starsArr || [],
          sportSeriesCode: filters.series,
          approvedStarRatingOnly: filters.confirmedRating || false,
          nationalMovementOnly: filters.nmo || false,
        },
      })
        .then((response) => {
          const { data } = response;

          this.events.push(...(data.list || []));
          this.pagination.totalItems = data.totalCount || 0;
          this.pagination.totalPages = Math.ceil(this.pagination.totalItems / this.pagination.pageSize);

          this.isLoading = false;
          this.isLoadingMore = false;
        });
    },
    scrollToTop() {
      if (this.$refs.topOfList) {
        const scrollPosition = this.$refs.topOfList.getBoundingClientRect().top + window.pageYOffset - 72;
        if (scrollPosition < window.pageYOffset) {
          window.scrollTo(0, scrollPosition);
        }
      }
    },
    onFiltersChange() {
      this.pagination.page = 1;
      this.onParamsChange();
    },
  },
  mounted() {
    this.parseQuery();
    this.isLoading = true;
  },
};
</script>

<style lang="scss" scoped>
@import "./Index";
</style>
