<template>
  <main class="layout__main">
    <Header
      v-bind="backLink"
      :shiftTitle="false"
      isWide
      :backButtonProps="backButtonProps"
    >
      <template v-slot:center v-if="themeSettings.noEventLinks">
        <a :href="themeUrls.Main">
          <img :src="themeImages.logo" alt="" style="width: var(--header-logo-width)">
        </a>
      </template>
      <template v-slot:title v-if="!themeSettings.noEventLinks && event.title && !isLoading">
        <div class="text-ellipsis" v-if="themeSettings.eventCode">
          <b>{{ event.title }}</b>
        </div>
        <Link
          v-else
          v-show="event.title"
          class="rr-link_ellipsis"
          :href="`${themeUrls.Events}/event/${event.code || event.id}`"
          :text="`<span class='color-text'>${event.title}</span>`"
          variant="secondary"
          size="md"
          icon-right="externa-link"
          target="_blank"
        ></Link>
      </template>
      <template v-if="!visibilityCompareButton" v-slot:right>
        <Button
          :to="{name: 'Compare', params: { eventCode, raceCode: currentRace.code }}"
          shape="square"
          variant="clear"
          icon-size="xl"
          icon-right="compare-2"
          :class="{'transparent': !event.title}"
        >
          <div class="follow-badge" v-show="eventFollowCount">
            {{ eventFollowCount > 99 ? '99+' : eventFollowCount }}
          </div>
        </Button>
      </template>
    </Header>
    <div class="rr-header-offset"></div>
    <div v-show="!isLoading">
    <EventIntro
      ref="intro"
      :beginDate="event.beginDate"
      :endDate="event.endDate"
      :location="event.place"
    >
      <EventSearch
        ref="search"
        :eventId="event.id"
        :races="event.races"
        @findResult="findResult"
      ></EventSearch>
    </EventIntro>
    <div ref="beforeTabs"></div>
    <Tabs
      v-if="navOptions.length > 1"
      :items="navOptions"
      variant="white"
      centered
      style="overflow-x: auto;"
    ></Tabs>
    </div>
    <router-view
      ref="view"
      v-if="!isLoading"
      :eventCode="eventCode"
      :event="event"
      :eventId="event.id"
      :raceId="currentRace.id"
      :race="race"
      @pageSizeChanged="$refs.search && $refs.search.setPageSize"
    >
      <template v-slot:myLaps>
        <div
          class="section__group text-center"
          v-if="isProChip || isBibTag"
        >
          <div
            class="text-xs"
            v-html="$t('event_results.my_laps_info', [isProChip ? 'MYLAPS&nbsp;ProChip' : 'MYLAPS&nbsp;BibTag'])"
          >
          </div>
          <img class="mt-16" src="@/assets/img/mylaps.svg" alt="MyLaps">
        </div>
      </template>
    </router-view>
    <Loader v-if="isLoading"></Loader>
  </main>
</template>

<script>
import { mapActions, mapGetters } from 'vuex';
import { TimingType } from '@/enums';
import disciplines from '@/assets/js/disciplines';
import Header from '@/sections/Header/HeaderExternalBack.vue';
import Tabs from '@/components/Tabs/Tabs.vue';
import EventIntro from './EventIntro/EventIntro.vue';
import EventSearch from './EventSearch/EventSearch.vue';

export default {
  name: 'Result',
  components: {
    Header,
    Tabs,
    EventIntro,
    EventSearch,
  },
  props: {
    eventCode: String,
  },
  data() {
    return {
      race: {
        val: null,
        options: [],
      },
      resultsPageSize: 0,
      isLoading: false,
    };
  },
  computed: {
    ...mapGetters('event', ['event']),
    ...mapGetters('follow', ['followList']),
    ...mapGetters('settings', ['themeUrls', 'themeSettings', 'themeImages', 'theme']),
    eventFollowCount() {
      return this.race.options.reduce((count, race) => {
        const key = `follow_${this.eventCode}_${race.id}`;
        return count + (this.followList[key] || []).length;
      }, 0);
    },
    currentRace() {
      return this.race.options.find((o) => o.code === this.race.val) || {};
    },
    navOptions() {
      return [
        {
          id: 'results',
          name: this.$t('event_results.results'),
          to: { name: 'Results', params: { raceCode: this.race.val } },
        },
        {
          id: 'leaders',
          name: this.$t('event_results.leaders'),
          to: { name: 'Leaders', params: { raceCode: this.race.val } },
          notAvailable: this.currentRace.disableLeaders,
        },
        {
          id: 'statistics',
          name: this.$t('event_results.statistics'),
          to: { name: 'Statistics', params: { raceCode: this.race.val } },
          notAvailable: this.event.hideStatistics,
        },
      ].filter((o) => !o.notAvailable);
    },
    backLink() {
      if (this.themeSettings.eventCode) {
        return {
          backHref: this.themeUrls.Main,
        };
      }
      return {
        backTo: { name: 'Index' },
      };
    },
    isProChip() {
      return this.currentRace.timingType === TimingType.MYLAPS_ProChip;
    },
    isBibTag() {
      return this.currentRace.timingType === TimingType.MYLAPS_BibTag;
    },
    backButtonProps() {
      const propsDict = {
        rzd: {
          shape: 'circle',
          variant: 'secondary',
          'icon-size': 'm',
          'icon-left': 'left',
        },
      };

      return propsDict[this.theme];
    },
    visibilityCompareButton() {
      const disableOnTenants = ['pionercup'];
      return disableOnTenants.includes(this.theme);
    },
  },
  methods: {
    ...mapActions('event', ['getEvent']),
    setData(data) {
      this.race.options = data.races || [];
      const { raceCode } = this.$route.params;
      const raceCodeValid = raceCode && this.race.options.find((o) => o.code === raceCode);
      this.race.val = raceCodeValid ? raceCode : this.race.options[0]?.code;
    },
    async loadEvent() {
      this.isLoading = true;
      await this.getEvent({
        eventIdOrCode: this.eventCode,
      });
      if (Object.keys(this.event).length) {
        this.setData(this.event);
        this.isLoading = false;
      }
    },
    findResult({ id, raceCode, page }) {
      const data = {
        id,
        page,
        raceCode: this.race.val !== raceCode ? raceCode : undefined,
      };
      const scrollPosition = window.scrollY;

      this.$router.replace({
        name: 'Results',
        params: {
          raceCode,
        },
      })
        .then(() => { window.scrollTo(0, scrollPosition); })
        .catch(() => {})
        .finally(() => {
          this.$refs.view.findResult(data);
        });
    },
    setEventImg() {
      if (this.themeImages['custom-event-bg']) {
        this.$refs.intro.setImage(this.themeImages['custom-event-bg']);
        return;
      }

      const eventImg = this.event.headerBannerUrl && this.event.headerBannerUrl.replace('-x-', '-x1000');
      const { disciplineCode } = this.event;

      let disciplineImg = disciplines[disciplineCode] && disciplines[disciplineCode].imgLg
        ? disciplines[disciplineCode].imgLg
        : disciplines.run.imgLg;
      disciplineImg = `${this.themeUrls.Events}/${disciplineImg}`;

      const img = new Image();

      img.onload = () => {
        this.$refs.intro.setImage(img.src);
      };
      img.onerror = () => {
        img.onerror = null;
        if (img.src === disciplineImg) {
          return;
        }
        img.src = disciplineImg;
      };
      img.src = eventImg || disciplineImg;
    },
  },
  watch: {
    'race.val': function f(val) {
      const scrollPosition = window.scrollY;
      this.$router.replace({
        params: {
          raceCode: val,
        },
        query: this.$route.query,
      })
        .then(() => { window.scrollTo(0, scrollPosition); })
        .catch(() => {});
    },
    isLoading(val) {
      if (!val) {
        this.setEventImg();
      }
    },
    $route: {
      handler(route) {
        const isAvailable = this.navOptions.some((o) => o.to.name === route.name);
        if (!isAvailable) {
          this.$router.replace(this.navOptions[0].to);
        }
      },
      immediate: true,
    },
  },
  created() {
    this.loadEvent();
  },
};
</script>

<style lang="scss" scoped>
@import "./Result";
</style>
