import { render, staticRenderFns } from "./EventBadges.vue?vue&type=template&id=f265da14&scoped=true&"
import script from "./EventBadges.vue?vue&type=script&lang=js&"
export * from "./EventBadges.vue?vue&type=script&lang=js&"
import style0 from "./EventBadges.vue?vue&type=style&index=0&id=f265da14&lang=scss&scoped=true&"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "f265da14",
  null
  
)

export default component.exports