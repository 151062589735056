<template>
  <div class="layout" id="app">
    <Icons :iconsContext="iconsContext"></Icons>
    <template v-if="isLoaded">
      <TenantHeader v-if="$route.meta.showHeader"></TenantHeader>
      <router-view :eventCode="eventCode"/>
      <TenantFooter></TenantFooter>
      <Alerts></Alerts>
    </template>
    <CookieGDPR></CookieGDPR>
  </div>
</template>

<script>
import { mapGetters, mapActions } from 'vuex';
import Icons from '@rr-component-library/icons/src/main';
import TenantHeader from '@/components/TenantHeader.vue';
import TenantFooter from '@/components/TenantFooter.vue';
import Alerts from '@/components/Alerts/Alerts.vue';
import CookieGDPR from '@/components/CookieGDPR/CookieGDPR.vue';

export default {
  name: 'App',
  components: {
    Icons,
    TenantHeader,
    TenantFooter,
    Alerts,
    CookieGDPR,
  },
  data() {
    return {
      sizes: {
        isMobile: false,
        isTablet: false,
        isDesktop: false,
      },
      isLoaded: false,
      iconsContext: require.context(
        '!svg-inline-loader?'
        + 'removeTags=true&removeSVGTagAttrs=false'
        + '!../src/assets/icons',
        false,
        /\w+\.svg$/i,
      ),
    };
  },
  provide() {
    return {
      sizes: this.sizes,
    };
  },
  computed: {
    ...mapGetters('settings', ['theme', 'themeLoaded', 'themeImages', 'themeSettings', 'userbackAvailable']),
    eventCode() {
      return this.themeSettings.eventCode || this.$route.params.eventCode;
    },
  },
  methods: {
    setSizes() {
      this.sizes.isMobile = window.innerWidth < 768;
      this.sizes.isTablet = window.innerWidth >= 768 && window.innerWidth < 1024;
      this.sizes.isDesktop = window.innerWidth >= 1024;
    },
    ...mapActions('settings', ['setupTheme']),
    ...mapActions('user', ['getUser']),
    initUserback() {
      if (window.Userback) {
        window.Userback.init('9201|19675|8JKkhdf7f0dN5GxQG49YWEUMswJQ3S8bwtEzKNSNcXvaEH4r15', {
          widget_settings: {
            autohide: true,
            language: this.$lang,
          },
        });
      }
    },
  },
  watch: {
    themeLoaded: {
      handler(val) {
        if (!val) {
          return;
        }

        if (this.userbackAvailable) {
          this.initUserback();
        }

        window.setRoutes();
        this.isLoaded = true;
      },
      immediate: true,
    },
  },
  beforeCreate() {
    window.setThemeSettings = () => this.setupTheme();
  },
  created() {
    this.getUser();
  },
  mounted() {
    this.setSizes();
    window.addEventListener('resize', this.setSizes);
  },
  unmounted() {
    window.removeEventListener('resize', this.setSizes);
  },
};
</script>

<style lang="scss">
@import "./App";
</style>
