<template>
  <div class="alerts">
    <Alert
      class="alerts__item"
      v-for="alert in alerts.slice(0, 1)"
      :key="alert.id"
      v-show="id === alert.id"
      v-bind="alert.props"
      closable
      @close="removeAlert(id)"
    ></Alert>
  </div>
</template>

<script>
export default {
  name: 'Alerts',
  data() {
    return {
      id: null,
      alerts: [],
    };
  },
  methods: {
    showAlert(id, props) {
      const isExist = this.alerts.find((a) => a.id === id);

      if (isExist) {
        return;
      }
      this.alerts.push({ id, props });

      if (!this.id) {
        this.id = id;
      }
    },
    removeAlert(id) {
      const isActive = this.id === id;

      setTimeout(() => {
        this.alerts = this.alerts.filter((a) => a.id !== id);

        if (isActive) {
          this.id = this.alerts[0]?.id || null;
        }
      }, 200);
    },
  },
  created() {
    this.$root.showAlert = this.showAlert;
  },
};
</script>

<style lang="scss" scoped>
.alerts {
  display: flex;
  flex-direction: column;
  align-items: center;
  position: fixed;
  bottom: 0;
  left: 0;
  width: 100%;
  z-index: 100;
  padding: 12px;
  pointer-events: none;

  @media (min-width: breakpoint(md)) {
    padding: 16px;
  }

  @media (min-width: breakpoint(xl)) {
    padding: 24px;
  }

  .alerts__item {
    flex: 0 0 auto;
    width: 100%;
    max-width: 100%;
    pointer-events: auto;

    @media (min-width: breakpoint(sm)) {
      width: auto;
    }
  }
}
</style>
