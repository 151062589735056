<template>
  <div
    class="events-filters"
    :class="{'events-filters_active': isActive}"
  >
    <div
      class="events-filters__overlay"
      @click="() => { cancel(); close(); }"
    ></div>
    <div class="events-filters__container">
      <!-- Desktop filters : BEGIN -->
      <div class="d-none d-md-block">
        <FilterItem
          v-if="isEnabled('discipline')"
          id="discipline"
          :opened="state.discipline"
          :name="$t('filters.sportType.title')"
        >
          <Select
            v-model="filters.discipline.val"
            :options="filters.discipline.options"
            :placeholder="$t('filters.sportType.placeholder')"
            searchable
            clearable
            wrap-text
            :noResultText="$t('select.noResults')"
            :noOptionsText="$t('select.loading')"
          ></Select>
        </FilterItem>
        <FilterItem
          v-if="isEnabled('dateFrom, dateTo')"
          id="date"
          :opened="state.date"
          :name="$t('filters.date.title')"
        >
          <EventsDatepicker v-model="date"></EventsDatepicker>
        </FilterItem>
        <FilterItem
          v-if="isEnabled('location')"
          id="location"
          :opened="state.location"
          :name="$t('filters.place.title')"
        >
          <Select
            v-model="filters.location.val"
            :options="filters.location.options"
            :placeholder="$t('filters.place.placeholder')"
            searchable
            clearable
            wrap-text
            track-by="name"
            :noResultText="noResultSearchLocationText"
            :noOptionsText="noOptionsSearchLocationText"
            @search="findLocation"
          >
          </Select>
        </FilterItem>
        <FilterItem
          v-if="isEnabled('ratingFrom')"
          id="rating"
          :opened="state.rating"
          :name="$t('filters.starRating.title') + ' ⭐'"
        >
          <div class="filter__content-item">
            <div class="slider">
              <VueSlider
                class="rating-slider"
                v-model="rating"
                :data="filters.ratingFrom.options"
                :data-value="'id'"
                :data-label="'name'"
                tooltip="none"
                :dotSize="16"
                :contained="true"
                :duration="0.3"
                :dot-options="[{disabled: false}, {disabled: true}]"
                :lazy="true"
              ></VueSlider>
            </div>
          </div>
          <div class="filter__content-item">
            <Checkbox
              v-model="filters.confirmedRating.val"
            >
              {{ $t('filters.starRating.onlyApproved') }}
            </Checkbox>
          </div>
        </FilterItem>
        <FilterItem
          v-if="isEnabled('nmo')"
          :opened="true"
          :toggleable="false"
        >
          <template slot="header">
            <label for="nbdOnly" style="user-select: none;">
              <span class="filter__title">{{ $t('filters.nroEvents.title') }}</span>
            </label>&nbsp;&nbsp;
            <RrSwitch
              v-model="filters.nmo.val"
              class="ml-auto"
              id="nbdOnly"
              name="nbd-only"
            ></RrSwitch>
          </template>
          <div class="text-sm">{{ $t('filters.nroEvents.text') }}</div>
        </FilterItem>
        <FilterItem
          v-if="isEnabled('series')"
          id="series"
          :opened="state.series"
          :name="$t('filters.series.title')"
        >
          <Select
            v-model="filters.series.val"
            :options="filters.series.options"
            :placeholder="$t('filters.series.title')"
            clearable
            wrap-text
            :noResultText="$t('select.noResults')"
            :noOptionsText="$t('select.loading')"
          ></Select>
        </FilterItem>
        <div class="events-filters__footer">
          <Row
            class="rr-grid_8"
            :class="`events-filters__row-${this.theme}`">
            <Col size="stretch">
              <Button
                variant="secondary"
                :text="$t('filters.clear')"
                wide
                @click="() => { reset(); close(); }"
              ></Button>
            </Col>
            <Col size="stretch">
              <Button
                variant="primary"
                :text="$t('filters.show')"
                wide
                @click="apply"
              ></Button>
            </Col>
          </Row>
        </div>
      </div>
      <!-- Desktop filters : END -->
      <!-- Mobile filters : BEGIN -->
      <div class="d-md-none">
        <MobileModal
          ref="mobileFilters"
          :name="$t('filters.title')"
          :count="filtersCount"
          :applyText="$t('filters.show')"
          :showResetBtn="true"
          @close="close"
          @cancel="cancel"
          @reset="reset"
          @apply="apply"
        >
          <div class="mobile-modal__full">
            <FilterItemExtended
              v-if="isEnabled('discipline')"
              :name="$t('filters.sportType.title')"
              v-model="filters.discipline.val"
              :defaultValue="filters.discipline.defaultVal"
            >
              <template v-slot:value="props">
                {{ props.isNotDefault
                ? getOptionName(filters.discipline.options, filters.discipline.val, 'id', 'name')
                : $t('filters.sportType.empty') }}
              </template>
              <template slot="filter">
                <span></span>
                <Radio
                  v-for="item in filters.discipline.options"
                  :key="'discipline' + item.id"
                  class="w-100"
                  v-model="filters.discipline.val"
                  :value="item.id"
                >
                  {{ item.name }}
                </Radio>
              </template>
            </FilterItemExtended>
            <FilterItemExtended
              v-if="isEnabled('dateFrom, dateTo')"
              :name="$t('filters.date.title')"
              v-model="date"
              :defaultValue="[null, null]"
              :changed="dateChanged"
              @open="() => { $refs.mobileDatepicker.open() }"
              @reset="() => { $refs.mobileDatepicker.clearDate() }"
              @apply="() => { $refs.mobileDatepicker.confirmDate() } "
            >
              <template v-slot:value="props">
                {{ props.isNotDefault ? formattedDate : $t('filters.date.empty') }}
              </template>
              <template slot="filter">
                <div class="mobile-modal__full">
                  <EventsDatepicker
                    v-model="date"
                    ref="mobileDatepicker"
                    :mobile="true"
                  ></EventsDatepicker>
                </div>
              </template>
            </FilterItemExtended>
            <FilterItemExtended
              v-if="isEnabled('location')"
              :name="$t('filters.place.title')"
              :defaultValue="filters.location.defaultVal"
              v-model="filters.location.val"
              @open="filters.location.query = null"
            >
              <template v-slot:value="props">
                {{ props.isNotDefault
                ? getOptionName(filters.location.options, filters.location.val, 'name', 'name')
                : $t('filters.place.empty') }}
              </template>
              <template slot="filter">
                <div class="mobile-modal__full">
                  <div class="mobile-modal__body-item">
                    <label class="mobile-modal__input">
                      <input
                        v-model="filters.location.query"
                        ref="locationInput"
                        class="mobile-modal__input-el"
                        name="location"
                        type="text"
                        :placeholder="$t('filters.place.placeholder')"
                        autocomplete="off"
                        @input="findLocation($event.target.value)"
                      >
                    </label>
                  </div>
                  <div v-if="!locationSearch.length" class="mobile-modal__body-item mobile-modal__body-item_center">
                    <div v-if="isNoResultSearchLocation" class="mobile-modal__text-center">Начните вводить название города</div>
                    <div v-else class="mobile-modal__text-center">Ничего не найдено</div>
                  </div>
                  <div class="mobile-modal__body-item">
                    <div
                      class="base-dropdown__item"
                      v-for="item in locationSearch"
                      :key="'location' + item.name"
                    >
                      <div
                        class="base-dropdown__link"
                        @click="() => {
                          filters.location.val = item.name;
                          filters.location.query = item.name
                        }"
                      >
                        <icon
                          class="base-dropdown__icon color-muted"
                          name="environment">
                        </icon>
                        <span class="base-dropdown__text" style="font-weight: normal">
                          {{ item.name }}
                        </span>
                      </div>
                    </div>
                  </div>
                </div>
              </template>
            </FilterItemExtended>
            <FilterItemExtended
              v-if="isEnabled('ratingFrom')"
              :name="$t('filters.starRating.title') +' ⭐️'"
              v-model="rating"
            >
              <template slot="inlineFilter">
                <div class="filter-item-ext__body-item">
                  <div class="slider">
                    <vue-slider
                      class="rating-slider"
                      v-model="rating"
                      :data="filters.ratingFrom.options"
                      :data-value="'id'"
                      :data-label="'name'"
                      tooltip="none"
                      :dotSize="16"
                      :contained="true"
                      :duration="0.3"
                      :dot-options="[{disabled: false}, {disabled: true}]"
                      :lazy="true"
                    ></vue-slider>
                  </div>
                </div>
                <div class="filter-item-ext__body-item">
                  <Checkbox
                    v-model="filters.confirmedRating.val"
                  >
                    {{ $t('filters.starRating.onlyApproved') }}
                  </Checkbox>
                </div>
              </template>
            </FilterItemExtended>
            <FilterItemExtended
              v-if="isEnabled('nmo')"
              :name="$t('filters.nroEvents.title')"
            >
              <template slot="value">
                <RrSwitch
                  v-model="filters.nmo.val"
                  class="ml-auto"
                  name="nbd-only"
                ></RrSwitch>
              </template>
            </FilterItemExtended>
            <FilterItemExtended
              v-if="isEnabled('series')"
              :name="$t('filters.series.title')"
              :defaultValue="filters.series.defaultVal"
              v-model="filters.series.val"
            >
              <template v-slot:value="props">
                {{ props.isNotDefault
                ? getOptionName(filters.series.options, filters.series.val, 'id', 'name')
                : $t('filters.series.empty') }}
              </template>
              <template slot="filter">
                <span></span>
                <Radio
                  v-for="item in filters.series.options"
                  :key="item.id"
                  v-model="filters.series.val"
                  class="w-100"
                  :value="item.id"
                >
                  {{ item.name }}
                </Radio>
              </template>
            </FilterItemExtended>
          </div>
        </MobileModal>
      </div>
      <!-- Mobile filters : END -->
    </div>
  </div>
</template>

<script>
import { mapGetters } from 'vuex';
import eventsApi from '@/services/events-api';
import { isEqual } from 'lodash';
import Select from '@rr-component-library/select/src/main';
import Checkbox from '@rr-component-library/checkbox/src/main';
import Radio from '@rr-component-library/radio/src/main';
import RrSwitch from '@/components/Switch/Switch.vue';
import VueSlider from 'vue-slider-component';
import 'vue-slider-component/theme/default.css';
import getData from '@/data';
import EventsDatepicker from './EventsDatepicker.vue';
import FilterItem from './FilterItem.vue';
import FilterItemExtended from './FilterItemExtended.vue';
import MobileModal from './MobileModal.vue';

const ratingOptions = [
  { id: 'null', name: 'Нет' },
  { id: 'one', name: '3' },
  { id: 'oneplus', name: '3+' },
  { id: 'three', name: '4' },
  { id: 'threeplus', name: '4+' },
  { id: 'five', name: '5' },
  { id: 'fiveplus', name: '5+' },
];

const ratingArr = [];
ratingOptions.forEach((item) => {
  ratingArr.push(item.id);
});

const cachedValues = {};

export default {
  name: 'EventsFilters',
  components: {
    FilterItemExtended,
    FilterItem,
    Select,
    Checkbox,
    Radio,
    RrSwitch,
    VueSlider,
    MobileModal,
    EventsDatepicker,
  },
  data() {
    return {
      config: getData('events-filters-config.js') || {},
      isMounted: false,
      isActive: false,
      filters: {
        discipline: {
          val: null,
          defaultVal: null,
          options: [],
          key: 'id',
        },
        dateFrom: {
          val: null,
          defaultVal: null,
        },
        dateTo: {
          val: null,
          defaultVal: null,
        },
        location: {
          val: null,
          defaultVal: null,
          options: [],
          query: null,
        },
        place: {
          val: null,
          query: null,
        },
        series: {
          val: null,
          defaultVal: null,
          options: [],
          key: 'id',
        },
        ratingFrom: {
          val: null,
          defaultVal: null,
          options: ratingOptions,
        },
        confirmedRating: {
          val: false,
          defaultVal: false,
        },
        nmo: {
          val: false,
          defaultVal: false,
        },
      },
      state: {
        discipline: true,
        date: true,
        location: true,
      },
      loadingLocation: false,
      isNoResultSearchLocation: true,
    };
  },
  computed: {
    ...mapGetters('settings', ['theme']),
    // ...mapState('events', { eventsFilters: 'filters' }),
    enabledFilters() {
      return this.config.enabled || Object.keys(this.filters);
    },
    locationSearch() {
      const { options, query } = this.filters.location;
      if (options.length < 1 || !query) {
        return [];
      }

      const result = [];
      for (let i = 0; i < options.length && result.length < 6; i += 1) {
        const item = options[i];
        if (item.name.toLowerCase().includes(query.toLowerCase())) {
          result.push(item);
        }
      }

      return result;
    },
    date: {
      get() {
        const from = this.filters.dateFrom.val;
        const to = this.filters.dateTo.val;
        return [
          from ? new Date(this.filters.dateFrom.val) : null,
          to ? new Date(this.filters.dateTo.val) : null,
        ];
      },
      set(value) {
        this.filters.dateFrom.val = value[0] && this.$moment(value[0]).format('YYYY-MM-DD');
        this.filters.dateTo.val = value[1] && this.$moment(value[1]).format('YYYY-MM-DD');
      },
    },
    rating: {
      get() {
        const { val, options } = this.filters.ratingFrom;
        const from = val || options[0].id;
        return [from, options[options.length - 1].id];
      },
      set(value) {
        const { options } = this.filters.ratingFrom;
        const [from] = value;
        this.filters.ratingFrom.val = from === options[0].id ? null : from;
      },
    },
    formattedDate() {
      return this.date[0] && this.$refs.mobileDatepicker
        ? this.$refs.mobileDatepicker.formatValue(this.date)
        : null;
    },
    dateChanged() {
      if (!this.isMounted) {
        return false;
      }
      const datepicker = this.$refs.mobileDatepicker;
      if (!this.$moment(datepicker.picker.innerValue[0]).isValid()) {
        return false;
      }
      return !isEqual(datepicker.picker.value, datepicker.picker.innerValue);
    },
    filtersCount() {
      let count = 0;
      const fields = ['discipline', 'location', 'nmo', 'series'];

      for (let i = 0; i < fields.length; i += 1) {
        const key = fields[i];
        const item = this.filters[key];
        if (item && (!isEqual(item.val, item.defaultVal))) {
          count += 1;
        }
      }

      if (
        !isEqual(this.rating, ['null', 'fiveplus'])
        || this.filters.confirmedRating.val !== this.filters.confirmedRating.defaultVal
      ) {
        count += 1;
      }

      if (
        this.filters.dateFrom.val !== this.filters.dateFrom.defaultVal
        || this.filters.dateTo.val !== this.filters.dateTo.defaultVal
      ) {
        count += 1;
      }

      return count;
    },
    noResultSearchLocationText() {
      return this.isNoResultSearchLocation ? this.$t('select.startCity') : this.$t('select.noResults');
    },
    noOptionsSearchLocationText() {
      return this.isNoResultSearchLocation ? this.$t('select.startCity') : this.$t('select.loading');
    },
  },
  methods: {
    isEnabled(filterIds) {
      if (!filterIds) {
        return false;
      }
      const arr = filterIds
        .split(',')
        .map((id) => id.trim());

      return arr.some((id) => this.enabledFilters.indexOf(id) !== -1);
    },
    setFilters(filters) {
      this.filters.discipline.options = (filters.disciplines || []).filter((o) => o.name);
      this.filters.series.options = filters.series || [];
      this.parseQuery();
    },
    setFiltersLocation(filters) {
      this.filters.location.options = filters;
    },
    open() {
      this.isActive = true;
      this.$el.scrollTo(0, 0);
      if (this.$refs.mobileFilters) {
        this.$refs.mobileFilters.open();
      }
      document.body.style.overflow = 'hidden';
      this.saveValues();
    },
    close() {
      this.isActive = false;
      document.body.style.overflow = null;
    },
    saveValues() {
      Object.keys(this.filters).forEach((key) => {
        cachedValues[key] = this.filters[key].val;
      });
    },
    reset() {
      this.close();
      Object.keys(this.filters).forEach((key) => {
        this.filters[key].val = this.filters[key].defaultVal;
      });

      this.$emit('change', { reset: true });
    },
    cancel() {
      Object.keys(cachedValues).forEach((key) => {
        this.filters[key].val = cachedValues[key];
      });
    },
    getValues() {
      const value = {};

      const srFrom = this.filters.ratingFrom.val;
      if (srFrom) {
        const i = ratingArr.indexOf(srFrom);
        value.starsArr = i > 0 ? ratingArr.slice(i) : null;
      }

      Object.keys(this.filters).forEach((key) => {
        if (key === 'place' && this.filters.location.val) {
          value.place = this.filters.location.val;
        } else if (key === 'location') {
          const valLocation = this.filters.location.val;
          const current = this.filters.location.options.find((item) => item.name === valLocation);
          if (current && current.id) {
            value.location = current.id;
          }
        } else {
          const { val } = this.filters[key];
          value[key] = val;
        }
      });

      return value;
    },
    apply() {
      this.close();
      this.setQuery();
      this.$emit('change');
    },
    getOptionName(options, id, key, label) {
      if (!id) {
        return null;
      }
      const k = key || 'id';
      const l = label || 'name';

      for (let i = 0; i < options.length; i += 1) {
        const option = options[i];
        if (option[k] === id) {
          return option[l];
        }
      }
      return null;
    },
    setQuery() {
      const query = { ...this.$route.query };
      Object.keys(this.filters)
        .forEach((key) => {
          if (key === 'location') {
            const { val } = this.filters.location;
            const current = this.filters.location.options.find((item) => item.name === val);
            query.location = current?.id || undefined;
            query.place = current?.place || undefined;
          } else {
            query[key] = this.filters[key].val || undefined;
          }
        });
      this.$router.replace({ query })
        .catch(() => {});
    },
    async setPlace(val) {
      const result = await eventsApi.findPlaces(val);
      this.setFiltersLocation(result.data);
      const current = result.data.find((item) => item.name === val);
      this.filters.location.val = current.name;
    },
    async parseQuery() {
      const { query } = this.$route;
      if (query?.place) {
        await this.setPlace(query.place);
      }
      Object.keys(query).forEach((key) => {
        if (key !== 'place') {
          const item = this.filters[key];
          if (item) {
            let val = query[key];

            if (item.type
              && item.type === 'array'
              && !Array.isArray(val)
            ) {
              val = [val];
            }

            if (typeof item.defaultVal === 'boolean') {
              val = val === true || val === 'true';
            }

            if (!this.filters[key].options
              || this.filters[key].options.find((o) => o.id === val)) {
              this.filters[key].val = val;
            }
          }
        }
      });

      this.$emit('change');
    },
    loadFilters() {
      return eventsApi.getEventsFilters()
        .then((response) => {
          this.setFilters(response.data || {});
        });
    },
    async findLocation(query) {
      this.isNoResultSearchLocation = query.length < 3;
      if (query.length > 2) {
        this.loadingLocation = true;
        try {
          const result = await eventsApi.findPlaces(query);
          this.setFiltersLocation(result.data);
        } catch (error) {
          console.error(error);
        } finally {
          this.loadingLocation = false;
        }
      } else if (query) {
        this.filters.location.options = [];
      }
    },
  },
  watch: {
    filtersCount(val) {
      this.$emit('filtersCountChanged', val);
    },
  },
  mounted() {
    this.loadFilters();
    this.isMounted = true;
  },
  created() {
    const state = localStorage.getItem('eventsFilterState');
    if (state) {
      this.state = JSON.parse(state);
    } else {
      localStorage.setItem('eventsFilterState', JSON.stringify(this.state));
    }
  },
  beforeDestroy() {
    if (this.isActive) {
      this.close();
    }
  },
};
</script>

<style lang="scss">
@import "./IndexFilters";
</style>
