import disciplines from '@/assets/disciplines.json';
import disciplinesRu from '@/assets/disciplines-ru.json';
import disciplinesEn from '@/assets/disciplines-en.json';
import stageDisciplinesMapping from '@/assets/stage-disciplines-mapping.json';

const disciplinesLoc = {
  ru: disciplinesRu,
  en: disciplinesEn,
};

const mapping = {
  stage: stageDisciplinesMapping,
};

function getDisciplineCode(disciplineCode, codeMapping) {
  if (!disciplineCode) {
    return null;
  }

  return codeMapping && mapping[codeMapping]
    ? mapping[codeMapping][disciplineCode.toLowerCase()]
    : disciplineCode;
}

function getDiscipline(disciplineCode, codeMapping) {
  if (!disciplineCode) {
    return null;
  }

  const code = getDisciplineCode(disciplineCode, codeMapping);
  return disciplines[code] || null;
}

function getDisciplineIcon(disciplineCode, codeMapping) {
  return getDiscipline(disciplineCode, codeMapping)?.icon || null;
}

function getDisciplineName(disciplineCode, lang) {
  const loc = disciplinesLoc[lang || 'ru'];
  return (loc && loc[disciplineCode]) || null;
}

export {
  getDisciplineCode,
  getDiscipline,
  getDisciplineIcon,
  getDisciplineName,
};
