<template>
  <label class="rr-switch">
    <input
      :id="id"
      class="rr-switch__input"
      :type="type"
      :disabled="disabled"
      :value="value"
      v-model="proxyChecked"
    />
    <span class="rr-switch__marker">
      <svg
        class="rr-switch__marker-icon"
        xmlns="http://www.w3.org/2000/svg"
        viewBox="0 0 24 24"
        id="icon-check-bold"
      >
        <path
          fill-rule="evenodd"
          d="M8.115 19.748a1.792 1.792 0 002.917 0l11.883-16.53c.214-.3 0-.718-.367-.718h-2.646c-.576 0-1.123.277-1.462.753l-8.863 12.34-4.017-5.592a1.803 1.803 0 00-1.462-.753H1.452a.455.455 0 00-.367.719l7.03 9.781z"
          clip-rule="evenodd"
        ></path>
      </svg>
      <svg class="rr-switch__marker-icon" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24">
        <path
          fill-rule="evenodd"
          d="M13.649 12l8.29-9.598c.14-.16.023-.402-.192-.402h-2.52a.521.521 0 00-.389.175L12 10.092 5.162 2.175A.51.51 0 004.773 2h-2.52c-.215 0-.332.242-.193.402L10.351 12l-8.29 9.598a.238.238 0 00-.037.261c.041.086.13.141.229.141h2.52c.149 0 .291-.064.389-.175L12 13.908l6.838 7.917c.095.11.237.175.389.175h2.52c.215 0 .332-.242.193-.402L13.649 12z"
          clip-rule="evenodd"
        ></path>
      </svg>
    </span>
  </label>
</template>

<script>
export default {
  name: 'RrSwitch',
  props: {
    id: String,
    disabled: {
      type: Boolean,
      default: false,
    },
    type: {
      type: String,
      default: 'checkbox',
      validator: (value) => value.match(/(checkbox|radio)/),
    },
    checked: {
      type: [Array, Boolean, Number, String],
      default: false,
    },
    value: {
      type: [String, Number],
      default: null,
    },
  },
  model: {
    prop: 'checked',
    event: 'change',
  },
  computed: {
    proxyChecked: {
      get() {
        return this.checked;
      },
      set(val) {
        this.$emit('change', val);
      },
    },
  },
};
</script>

<style lang="scss" scoped>
  @import "./Switch";
</style>
