<template>
  <div
    class="broadcast-intro">
    <div class="container">
      <div
        class="broadcast-intro__img"
        v-show="eventImage"
        :style="{backgroundImage: `url(${eventImage})`}"
      ></div>
      <div class="broadcast-intro__overlay"></div>
      <div class="broadcast-intro__content">
        <div class="broadcast-intro__item">
          <h1
            class="broadcast-intro__title"
            :class="{ 'broadcast-intro__title_uppercase' : theme === 'bfla'}"
          >{{ $t('event_results.title') }}</h1>
        </div>
        <div class="broadcast-intro__item">
          <slot></slot>
        </div>
        <div class="broadcast-intro__item" v-if="date || weather || location">
          <div class="broadcast-intro__info">
            <div class="broadcast-intro__info-row">
              <div class="broadcast-intro__info-item" v-if="date">
                <div class="text-with-icon text-nowrap">
                  <Icon class="icon" name="calendar" size="m"></Icon>
                  <span v-html="date"></span>
                </div>
              </div>
              <div class="broadcast-intro__info-item" v-if="weather">
                <WeatherBadge
                  :weather="weather"
                  simple
                ></WeatherBadge>
              </div>
              <div class="broadcast-intro__info-item" v-if="location">
                <div class="text-with-icon text-nowrap">
                  <Icon class="icon" name="environment" size="m"></Icon>
                  <span v-html="locationClipped" :title="location"></span>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { mapGetters } from 'vuex';
import { formatDate } from '@/assets/js/helpers';
import WeatherBadge from '@/components/WeatherBadge/WeatherBadge.vue';

export default {
  name: 'EventIntro',
  components: {
    WeatherBadge,
  },
  props: {
    weather: Object,
    beginDate: String,
    endDate: String,
    location: String,
  },
  data() {
    return {
      eventImage: null,
    };
  },
  computed: {
    ...mapGetters('settings', ['theme']),
    date() {
      return formatDate([this.beginDate, this.endDate], { fillYear: true });
    },
    locationClipped() {
      if (!this.location) {
        return '';
      }
      return this.location.length - 24 > 3
        ? `${this.location.slice(0, 24)}&#8230;`
        : this.location;
    },
  },
  methods: {
    setImage(img) {
      this.eventImage = img;
    },
  },
};
</script>

<style lang="scss" scoped>
@import "./EventIntro";
</style>
