<template>
  <div
    class="weather-badge"
    :class="[
      size && 'weather-badge_' + size,
      simple && 'weather-badge_simple'
    ]"
  >
    <img
      v-show="icon"
      class="weather-badge__icon"
      :src="require(`./img/${icon}.svg`)"
      alt="">
    <div class="weather-badge__text">{{ value }}</div>
  </div>
</template>

<script>
export default {
  name: 'WeatherBadge',
  props: {
    weather: Object,
    size: String,
    simple: Boolean,
  },
  data() {
    return {
      icons: {
        'icon-cloud-fog': 'haze',
        'icon-sun': 'summer',
        'icon-cloudy': 'partly-cloudy-day',
        'icon-cloud': 'clouds',
        'icon-rainy': 'rain-cloud',
        'icon-rainy2': 'rain',
        'icon-snowy': 'snow',
        'icon-cloudy2': 'clouds',
        'icon-lightning': 'storm',
      },
    };
  },
  computed: {
    value() {
      return `${Math.round(this.weather.t - 273)}°C`;
    },
    icon() {
      return this.weather.i ? this.icons[this.weather.i] : null;
    },
  },
};
</script>

<style lang="scss" scoped>
.weather-badge {
  display: inline-flex;
  align-items: center;
  font-weight: $font-weight-semibold;
  background-color: $color-white;
  border-radius: 99px;
  box-shadow: $elevation-deth-4;
  white-space: nowrap;
  @include text-lg;
  height: 40px;
  padding: 0 12px;

  &_simple {
    height: auto !important;
    padding: 0 !important;
    background: none;
    box-shadow: none;
  }

  &_sm {
    @include text-sm;
    height: 32px;
    padding: 0 8px;
  }

  &_lg {
    font-size: 22px;
    line-height: 32/22*1em;
    height: 48px;
    padding: 0 16px;
  }

  &__icon {
    width: 22px;
    height: 22px;
    margin-right: 4px;

    svg {
      display: block;
    }

    .weather-badge_sm & {
      width: 18px;
      height: 18px;
      margin-right: 2px;
    }

    .weather-badge_lg & {
      width: 26px;
      height: 26px;
      margin-right: 6px;
    }
  }
}
</style>
