import Vue from 'vue';
import VueRouter from 'vue-router';
import PageNotFound from '@/sections/PageNotFound/PageNotFound.vue';
import Index from '../views/Index/Index.vue';
import Result from '../views/Result/Result.vue';

Vue.use(VueRouter);

let routes = [
  {
    name: 'Index',
    path: '/',
    path_oneEventResults: false,
    component: Index,
    meta: {
      backLevel: 0,
      showHeader: true,
      footerType: 'full',
    },
  },
  {
    name: 'ResultsMain',
    path: '/event/:eventCode',
    path_oneEventResults: '/',
    component: Result,
    children: [
      {
        name: 'Results',
        path: 'results/:raceCode?',
        path_oneEventResults: ':raceCode?',
        component: () => import(/* webpackChunkName: 'result-results' */ '../views/ResultResults/Results.vue'),
        meta: {
          backLevel: 2,
        },
      },
      {
        name: 'Leaders',
        path: 'leaders/:raceCode?',
        component: () => import(/* webpackChunkName: 'result-leaders' */ '../views/ResultLeaders/Leaders.vue'),
        meta: {
          backLevel: 2,
        },
      },
      {
        name: 'Statistics',
        path: 'statistics/:raceCode?',
        component: () => import(/* webpackChunkName: 'result-statistics' */ '../views/ResultStatistics/Statistics.vue'),
        meta: {
          backLevel: 2,
        },
      },
    ],
  },
  {
    name: 'Compare',
    path: '/event/:eventCode/compare/:raceCode?',
    path_oneEventResults: '/compare/:raceCode?',
    component: () => import(/* webpackChunkName: 'compare' */ '../views/Compare/Compare.vue'),
    meta: {
      backLevel: 3,
    },
  },
  {
    name: 'Participant',
    path: '/participant/:eventCode/:raceCode/:participantId',
    path_oneEventResults: '/participant/:raceCode/:participantId',
    component: () => import(/* webpackChunkName: 'participant-member' */ '../views/Participant/Participant.vue'),
    meta: {
      backLevel: 4,
    },
  },
  {
    name: 'Team',
    path: '/team/:eventCode/:raceCode/:participantId',
    path_oneEventResults: '/team/:raceCode/:participantId',
    component: () => import(/* webpackChunkName: 'participant-team' */ '../views/Team/Team.vue'),
    meta: {
      backLevel: 4,
    },
  },
  // {
  //   name: 'PageNotFound',
  //   path: '/404',
  //   component: PageNotFound,
  // },
  {
    path: '*',
    component: PageNotFound,
  },
];

const router = new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL,
  routes: [],
  scrollBehavior(to, from, savedPosition) {
    if (from && to.name === from.name && to.path === from.path) {
      return null;
    }
    return savedPosition || { x: 0, y: 0 };
  },
});

function updateRoute(route, settings) {
  const path = route[`path_${settings.type}`] !== undefined
    ? route[`path_${settings.type}`]
    : route.path;

  const r = {
    ...route,
    path,
    alias: route.path,
    disabled: path === false,
  };

  if (r.children) {
    r.children = r.children
      .map((c) => updateRoute(c, settings))
      .filter((c) => !c.disabled);
  }

  return r;
}

window.setRoutes = () => {
  const type = window.RR_APP_SETTINGS?.settings?.routerType;

  routes = routes
    .map((r) => updateRoute(r, { type }))
    .filter((r) => !r.disabled);

  routes.forEach((route) => {
    router.addRoute(route);
  });
};

export default router;
