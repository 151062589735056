import axios from 'axios';

let configs = {
  store: undefined,
};

const apiConfig = ({ store }) => {
  configs = { ...configs, store };
};

const api = axios.create({
  baseURL: '',
});

axios.defaults.withCredentials = true;

export default api;
export { apiConfig };
