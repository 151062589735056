import moment from 'moment';

function stringPadLeft(string, pad, length) {
  return (new Array(length + 1).join(pad) + string).slice(-length);
}

function formatKilometers(meters) {
  let km = (meters || 0) / 1000;
  km = km < 1 ? Math.floor(km * 100) / 100 : Math.floor(km * 10) / 10;
  return km.toLocaleString();
}

function formatTime(seconds) {
  const time = Math.round(+(seconds || 0));
  let h = Math.floor(time / 3600);
  let m = Math.floor((time % 3600) / 60);
  let s = Math.floor((time % 3600) % 60);

  h = stringPadLeft(h, '0', 2);
  m = stringPadLeft(m, '0', 2);
  s = stringPadLeft(s, '0', 2);

  if (h > 0) {
    return `${h}:${m}:${s}`;
  }

  return `${m}:${s}`;
}

function debounce(func, wait, immediate) {
  let timeout;
  return function foo(...args) {
    const context = this;
    const later = function later() {
      timeout = null;
      if (!immediate) func.apply(context, args);
    };
    const callNow = immediate && !timeout;
    clearTimeout(timeout);
    timeout = setTimeout(later, wait);
    if (callNow) func.apply(context, args);
  };
}

function calculateAge(dateString) {
  if (!dateString) {
    return null;
  }
  const date = new Date(Date.now() - new Date(dateString).getTime());
  return Math.abs(date.getUTCFullYear() - 1970);
}

function calculateTime(ms, config) {
  const { fillHours } = config || {};
  const s = (ms > 0 ? ms : 0) / 1000;
  let hours = Math.floor(s / 3600);
  let minutes = Math.floor((s % 3600) / 60);
  let seconds = Math.round(s % 60);
  minutes = minutes < 10 ? `0${minutes}` : minutes;
  seconds = seconds < 10 ? `0${seconds}` : seconds;
  if (hours) {
    hours = hours < 10 ? `0${hours}` : hours;
    return `${hours}:${minutes}:${seconds}`;
  }
  if (fillHours) {
    return `00:${minutes}:${seconds}`;
  }
  return `${minutes}:${seconds}`;
}

function getQueryParam(item) {
  const value = window.location.search.match(new RegExp(`[?&]${item}=([^&]*)(&?)`, 'i'));
  return value ? value[1] : value;
}

/* eslint-disable no-param-reassign */
function groupBy(data, key) {
  return data.reduce((storage, item) => {
    const group = item[key];
    storage[group] = storage[group] || [];
    storage[group].push(item);
    return storage;
  }, {});
}

function isCurrentYear(momentDate) {
  if (!momentDate) {
    return false;
  }
  return momentDate.isSame(moment(), 'year');
}

function formatDate([start, end], options) {
  const { fillYear, month } = options || {};

  if (!start) {
    return null;
  }

  const startDate = moment(start);
  const endDate = end ? moment(end) : null;

  const yearFormat = (!fillYear && isCurrentYear(startDate)) ? '' : '[&nbsp;]YYYY';
  const monthFormat = month ? `[&nbsp;]${month}` : '[&nbsp;]MMMM';
  const monthStartFormat = month ? `[&nbsp;]${month}` : '[&nbsp;]MMM';
  const monthEndFormat = month ? `[&nbsp;]${month}` : '[&nbsp;]MMM';

  // One day
  if (!endDate || startDate.isSame(endDate, 'day')) {
    return startDate.format(`DD${monthFormat}${yearFormat}`);
  }

  // One month
  if (startDate.isSame(endDate, 'month')) {
    const startFormat = 'DD';
    const endFormat = `DD${monthFormat}${yearFormat}`;
    return `${startDate.format(startFormat)}–${endDate.format(endFormat)}`;
  }

  // One year
  if (startDate.isSame(endDate, 'year')) {
    const startFormat = `DD[&nbsp;]${monthStartFormat}`;
    const endFormat = `DD[&nbsp;]${monthEndFormat}${yearFormat}`;
    return `${startDate.format(startFormat)} – ${endDate.format(endFormat)}`;
  }

  const startFormat = `DD[&nbsp;]${monthStartFormat}${yearFormat}`;
  const endFormat = `DD${monthEndFormat}${yearFormat}`;
  return `${startDate.format(startFormat)} – ${endDate.format(endFormat)}`;
}

function markQueryInString(str, query) {
  if (!query || !str) {
    return str;
  }
  const q = query.toLowerCase();
  const i = str.toLowerCase()
    .indexOf(q);
  if (i !== -1) {
    const r = str.substring(i, i + q.length);
    return str.replace(r, `<b>${r}</b>`);
  }
  return str;
}

function groupArray(arr, key) {
  if (!arr || arr.length === 0) {
    return {};
  }
  return arr.reduce((items, item) => ({
    ...items,
    [item[key]]: item,
  }), {});
}

export {
  stringPadLeft,
  formatKilometers,
  formatTime,
  debounce,
  calculateAge,
  calculateTime,
  getQueryParam,
  groupBy,
  formatDate,
  markQueryInString,
  groupArray,
};
