<template>
  <header
    class="rr-header"
    :class="{
      'rr-header_scrolled': isScrolled,
      'rr-header_centered-layout': $slots.center,
    }"
  >
    <div class="rr-header__wrap">
      <div
        class="rr-header__container"
        :class="{'rr-header__container_md': !isWide}"
      >
        <div class="rr-header__col rr-header__col_left">
          <div class="rr-header__col-item">
            <router-link
              :is="backTo ? 'router-link' : backHref ? 'a' : 'span'"
              :to="backTo"
              :href="backHref"
            >
              <Button
                v-bind="backButtonProps || $options.backButtonPropsDefault"
                @click="$emit('clickBackButton')"
              ></Button>
            </router-link>
          </div>
        </div>
        <div class="rr-header__col rr-header__col_center-mobile">
          <div
            class="rr-header__col-item ml-auto mr-auto"
            v-if="$slots.center"
          >
            <slot name="center"></slot>
          </div>
          <div
            class="rr-header__col-item"
            v-if="$slots.service"
          >
            <slot name="service"></slot>
          </div>
          <div
            class="rr-header__col-item rr-header__col-item_shrink d-md-block"
            :class="{'d-none': shiftTitle && !isScrolled}"
            v-if="$slots.title"
          >
            <slot name="title"></slot>
          </div>
        </div>
        <div class="rr-header__col rr-header__col_right">
          <div class="rr-header__col-item">
            <slot name="right"></slot>
          </div>
        </div>
      </div>
      <div class="rr-header__bottom" v-if="shiftTitle && $slots.title" v-show="!isScrolled">
        <slot name="title"></slot>
      </div>
      <slot v-if="$slots.bottom" name="bottom"></slot>
    </div>
  </header>
</template>

<script>
export default {
  name: 'HeaderExternalBack',
  props: {
    isWide: Boolean,
    backHref: String,
    backButtonProps: {
      type: Object,
    },
    backTo: [String, Object],
    shiftTitle: {
      type: Boolean,
      default: true,
    },
    scrolledOffset: {
      type: Number,
      default: 48,
    },
  },
  data() {
    return {
      isScrolled: false,
    };
  },
  methods: {
    toggleHeader() {
      this.isScrolled = window.pageYOffset > this.scrolledOffset;
    },
  },
  mounted() {
    if (this.shiftTitle) {
      document.removeEventListener('scroll', this.toggleHeader);
      document.addEventListener('scroll', this.toggleHeader);
    }
  },
  backButtonPropsDefault: {
    shape: 'circle',
    variant: 'clear',
    'icon-size': 'xl',
    'icon-left': 'left',
  },
};
</script>

<style lang="scss" scoped>
@import "./Header";

.rr-header {

  &__col {

    &:not(:first-child) {
      margin-left: 0;
    }

    &:not(:last-child) {
      margin-right: 0;
    }

    &_left, &_right {
      flex: 0 0 auto;

      .rr-header_centered-layout & {
        flex: 0 0 0;
      }
    }

    &_center-mobile {

      @media (min-width: breakpoint(md)) {
        justify-content: flex-start;
      }
    }
  }
}
</style>

<style lang="scss">
.rr-header-offset {
  flex: 0 0 auto;
  height: 56px;

  @media (min-width: breakpoint(md)) {
    height: 64px;
  }
}
.rr-header-bottom-offset {
  flex: 0 0 auto;

  @media (max-width: breakpoint(md)) {
    height: 48px;
  }
}
</style>
