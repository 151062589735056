/* eslint no-shadow: ["error", { "allow": ["state", "getters"] }] */
import usersApi from '@/services/users-api';

const state = {
  user: {},
};

const getters = {
  user: (state) => state.user,
  isAuthorized: (state) => state.user.isAuthorized || false,
};

const mutations = {
  setUser(state, user) {
    state.user = user || {};
  },
};

const actions = {
  getUser({ commit }) {
    usersApi.getCurrentUser()
      .then((response) => {
        if (response.status === 200) {
          commit('setUser', response.data);
        }
      })
      .catch(() => {
        commit('setUser', null);
      });
  },
};

export default {
  namespaced: true,
  state,
  mutations,
  getters,
  actions,
};
