import Vue from 'vue';
import Vuex from 'vuex';
import countriesRu from '@/files/countries_ru.json';
import countriesEn from '@/files/countries_en.json';
import i18n from '../i18n';
import user from './modules/user';
import event from './modules/event';
import follow from './modules/follow';
import settings from './modules/settings';

const countries = {
  ru: countriesRu,
  en: countriesEn,
};

Vue.use(Vuex);

export default new Vuex.Store({
  modules: {
    user,
    event,
    follow,
    settings,
  },
  state: {
    countries,
  },
  getters: {
    countryById: () => (id) => {
      const values = countries[i18n.locale] || countries.ru;
      return values.find((country) => country.id === id);
    },
  },
});
