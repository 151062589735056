<template>
  <div class="general-partner-logo">
    <img
      src="./img/general-partner-logo.svg"
      alt="генеральный партнер ОАО РЖД лого"
      class="general-partner-logo__img">
    <p class="general-partner-logo__text">
      Реклама. ОАО "РЖД" <br>
      erid: 2SDnjbxJ9ei
    </p>
  </div>
</template>

<script>
export default {
  name: 'GeneralPartnerLogo',
};
</script>

<style lang="scss" scoped>
@import './GeneralPartnerLogo.scss';
</style>
