<template>
  <vue-ads-pagination
    ref="el"
    class="pagination"
    :page="page"
    :total-items="totalItems"
    :items-per-page="itemsPerPage"
    :max-visible-pages="maxVisiblePages"
    @page-change="changePage"
    @range-change="rangeChange"
  >
    <template
      slot="buttons"
      slot-scope="props"
    >
      <div class="pagination__row">
        <button
          class="pagination__button"
          :class="{
              'active': button.active,
              'pagination__prev': button.title === 'previous',
              'pagination__next': button.title === 'next'}
            "
          v-for="(button, key) in props.buttons"
          :key="key"
          @click="changePage(button.page)"
          type="button"
          :disabled="button.disabled"
        >
          <Icon
            v-if="button.title === 'previous'"
            name="left"
          ></Icon>
          <Icon
            v-else-if="button.title === 'next'"
            name="right"
          ></Icon>
          <template v-else>{{ button.html }}</template>
        </button>
      </div>
    </template>
  </vue-ads-pagination>
</template>

<script>
import VueAdsPagination from 'vue-ads-pagination';

export default {
  name: 'Pagination',
  components: {
    VueAdsPagination,
  },
  props: {
    page: Number,
    totalItems: Number,
    itemsPerPage: Number,
  },
  data() {
    return {
      maxVisiblePages: 3,
    };
  },
  methods: {
    updateMaxVisiblePages() {
      this.maxVisiblePages = window.innerWidth > 767 ? 3 : 1;
    },
    changePage(page) {
      if (this.page === page) {
        return;
      }
      this.$emit('change', page);
    },
    rangeChange(start, end) {
      this.$emit('rangeChange', start, end);
    },
  },
  mounted() {
    this.updateMaxVisiblePages();
    window.addEventListener('resize', () => {
      if (this.debouncedUpdate) {
        clearTimeout(this.debouncedUpdate);
      }
      this.debouncedUpdate = setTimeout(this.updateMaxVisiblePages, 200);
    });
  },
};
</script>

<style lang="scss" scoped>
@import "./Pagination";
</style>
