<template>
  <div class="event-badges">
    <div class="event-badges__item" v-if="isRR">
      <v-popover placement="top-center" trigger="hover">
        <img
          src="@/assets/img/badge-recommended-rr.svg"
          alt="RR"
        >
        <template slot="popover">
          Событие чемпионатов<br>RussiaRunning
        </template>
      </v-popover>
    </div>
    <div class="event-badges__item" v-if="isSportmaster">
      <v-popover placement="top-center" trigger="hover">
        <img
          src="@/assets/img/badge-sportmaster.svg"
          alt="SM"
        >
        <template slot="popover">
          Событие чемпионата<br>Спортмастер
        </template>
      </v-popover>
    </div>
    <div class="event-badges__item" v-if="isNbd">
      <v-popover placement="top-center" trigger="hover">
        <img
          src="@/assets/img/badge-nbd.svg"
          alt="NBD"
        >
        <template slot="popover">
          Входит в&nbsp;Национальное<br>беговое движение
        </template>
      </v-popover>
    </div>
    <div class="event-badges__item" v-if="starRating || approvedStarRating">
      <v-popover placement="top-center" trigger="hover">
        <div
          class="event-badges__star"
          :class="{'event-badges__star_approved': approvedStarRating}"
        >
          <Icon name="star"></Icon>
          <span class="event-badges__star-text">{{ approvedStarRating || starRating }}</span>
        </div>
        <template slot="popover">
          <div
            v-html="$t(approvedStarRating ? 'Подтверждённая звёздность' : 'Звёздность')"
          ></div>
        </template>
      </v-popover>
    </div>
    <div class="event-badges__item" v-if="voteRating">
      <v-popover
        placement="top-center"
        trigger="hover"
        popoverClass="event-badges__rating-popup"
      >
        <span class="event-badges__rating">{{ voteRating }}</span>
        <template slot="popover">
          Оценка события участниками
        </template>
      </v-popover>
    </div>
  </div>
</template>

<script>
export default {
  name: 'EventBadges',
  props: {
    isRR: Boolean,
    isSportmaster: Boolean,
    isNbd: Boolean,
    starRating: [String, Number],
    approvedStarRating: [String, Number],
    voteRating: [String, Number],
    // rr: Boolean,
    // rrTooltip: {
    //   type: String,
    //   default: 'Test',
    // },
  },
};
</script>

<style lang="scss" scoped>
@import "./EventBadges";
</style>
