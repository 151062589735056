var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"tabs",class:[
    _vm.variant && ("tabs_" + _vm.variant),
    _vm.centered && 'tabs_centered'
  ]},[_c('div',{staticClass:"tabs__container"},_vm._l((_vm.items),function(ref){
  var id = ref.id;
  var name = ref.name;
  var to = ref.to;
return _c(to ? 'router-link' : 'div',{key:id,tag:"div",staticClass:"tabs__item",class:{
        'tabs__item_active': _vm.active === id || _vm.$route.name === (to && to.name)
      },attrs:{"to":to},nativeOn:{"click":function($event){return _vm.changeTab(id)}}},[_vm._v("\n      "+_vm._s(name)+"\n    ")])}),1)])}
var staticRenderFns = []

export { render, staticRenderFns }