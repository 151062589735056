/* eslint no-shadow: ["error", { "allow": ["state", "getters"] }] */
import cookieHelper from '@/assets/js/cookieHelper';

function getListByKey(list, key) {
  return list[key]
  || cookieHelper.getCookie(key) ? JSON.parse(cookieHelper.getCookie(key)) : null;
}

const state = {
  followList: {},
  daysBeforeExpiration: 365,
};

const getters = {
  followList: (state) => state.followList,
  getFollowListByKeys: (state) => (keys) => (keys || [])
    .reduce((listByKeys, key) => {
      const listByKey = getListByKey(state.followList, key);
      return listByKey
        ? {
          ...listByKeys,
          [key]: listByKey,
        }
        : listByKeys;
    }, {}),
  getFollowListOfRace: (state) => ({
    eventCode,
    raceId,
  }) => {
    const key = `follow_${eventCode}_${raceId}`;
    return getListByKey(state.followList, key) || [];
  },
};

const mutations = {
  addRaceFollowList(state, { key, raceList }) {
    const list = state.followList;

    if (!raceList || !raceList.length) {
      delete list[key];
    } else {
      list[key] = raceList;
    }

    state.followList = {
      ...list,
    };
  },
};

const actions = {
  toggleFollow({ state, commit }, { id, eventCode, raceId }) {
    if (!id || !eventCode || !raceId) {
      return;
    }
    const key = `follow_${eventCode}_${raceId}`;
    let raceList = getListByKey(state.followList, key) || [];

    if (raceList.indexOf(id) === -1) {
      raceList.push(id);
    } else {
      raceList = raceList.filter((item) => item !== id);
    }

    const expires = new Date().setDate(new Date().getDate() + state.daysBeforeExpiration);

    cookieHelper.setCookie(key, JSON.stringify(raceList), {
      expires: new Date(expires),
    });

    commit('addRaceFollowList', { key, raceList });
  },
  updateRaceFollowList({ state, commit }, { eventCode, raceId }) {
    const key = `follow_${eventCode}_${raceId}`;
    const raceList = getListByKey(state.followList, key);
    commit('addRaceFollowList', { key, raceList });
  },
};

export default {
  namespaced: true,
  state,
  mutations,
  getters,
  actions,
};
