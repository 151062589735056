/* eslint no-shadow: ["error", { "allow": ["state", "getters"] }] */
import { getConfig, getConfigType } from '@/config';

const configType = getConfigType();
const config = getConfig(configType);

const state = {
  settings: window.RR_APP_SETTINGS || {},
};

const mutations = {
  setThemeSettings(state) {
    state.settings = window.RR_APP_SETTINGS || {};
  },
};

const getters = {
  themeLoaded: (state) => state.settings.theme != null,
  theme: (state) => state.settings.theme || 'rr',
  themeImages: (state) => state.settings.images || {},
  themeUrls: (state) => {
    if (state.settings.settings?.isExternalMain) {
      config.Main = state.settings.links.Main;
      delete state.settings.links.Events;
    }

    if (state.settings.theme === 'tbilisimarathon') {
      config.Diploma = 'https://media.witisi.photo';
      config.Video = 'https://video.witisi.photo';
    }

    if (configType !== 'prod') {
      return config;
    }

    return {
      ...config,
      ...state.settings.links,
    };
  },
  themeSettings: (state) => state.settings.settings || {},
  themeName: (state) => state.settings.name || null,
  themeData: (state) => state.settings.data || null,
  userbackAvailable: (state) => !state.settings.theme || state.settings.theme === 'rr',
};

const actions = {
  setupTheme({ commit }) {
    commit('setThemeSettings');
  },
};

export default {
  namespaced: true,
  state,
  mutations,
  getters,
  actions,
};
