<template>
  <div class="layout__header">
    <component v-if="currentTenant !== undefined" :is="currentTenant" />
    <template v-else>
      <Header activeMenu="results"></Header>
      <div class="rr-header-offset"></div>
    </template>
  </div>
</template>

<script>
import { mapGetters } from 'vuex';

export default {
  name: 'TenantHeader',
  components: {
    Header: () => import('@/components/Header/Header.vue'),
  },
  computed: {
    ...mapGetters('settings', ['theme']),
    currentTenant() {
      const components = {
        topliga2: () => import('@/components/_topliga2/Header/Header.vue'),
        bfla: () => import('@/components/_bfla/Header/Header.vue'),
        steelcharacter: () => import('@/components/_steelcharacter/Header/Header.vue'),
        samarkand: () => import('@/components/_samarkand/Header/Header.vue'),
        rzd: () => import('@/components/_rzd/Header/Header.vue'),
        pionercup: () => import('@/components/_pionercup/Header/Header.vue'),
        legkovrace: () => import('@/components/_legkovrace/Header.vue'),
        tulamarathon: () => import('@/components/_tula/Header.vue'),
      };

      return components[this.theme];
    },
  },
};
</script>
