<template>
  <div class="footer" v-if="!noFooter">
    <div class="footer__container container">
      <div class="footer__items">
        <div class="footer__item" v-if="userbackAvailable">
          <Link
            tag="button"
            class="color-white v-align-middle"
            icon-left="feedback"
            variant="inherit"
            size="sm"
            :text="$t('website_feedback')"
            @click="openFeedbackForm"
          ></Link>
        </div>
        <div class="footer__item">
          <div class="footer__social">
            <a
              v-for="item in socials"
              :key="item.icon"
              class="footer__social-item"
              :href="item.link"
              target="_blank"
            >
              <Icon :name="item.icon"></Icon>
            </a>
          </div>
        </div>
        <LangSwitch
          v-if="!hideLang"
          class="footer__item"
          :locales="themeSettings.languages"
          :locale="$lang"
          :dropdownOptions="{ triggerId: 'footerLangSwitch' }"
          hideCurrent
          @change="changeLang"
        >
          <template v-slot:default="{ language }">
            <Button
              id="footerLangSwitch"
              variant="ghost"
              view="light"
              size="sm"
              :text="language.text"
              icon-left="global"
            ></Button>
          </template>
        </LangSwitch>
        <div class="footer__item footer__copyright">
          <span v-html="copyright"></span>
          <template
            v-for="(link, i) in links"
          >
            <template v-if="i > 0 || copyright">&nbsp;&nbsp;|&nbsp;</template>
            <Link
              :key="i"
              class="color-white"
              :text="link.text"
              :href="link.link"
              target="_blank"
              variant="inherit"
              size="sm"
            ></Link>
          </template>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { mapGetters } from 'vuex';
import { setCulture } from '@/utils';
import LangSwitch from '@/components/LangSwitch/LangSwitch.vue';

export default {
  name: 'Footer',
  components: {
    LangSwitch,
  },
  i18n: {
    messages: {
      ru: {
        website_feedback: 'Отзыв о&nbsp;сайте',
      },
      en: {
        website_feedback: 'Website Feedback',
      },
    },
  },
  data() {
    return {
      year: new Date().getFullYear(),
    };
  },
  computed: {
    ...mapGetters('settings', ['theme', 'userbackAvailable', 'themeUrls', 'themeSettings']),
    data() {
      try {
        // eslint-disable-next-line global-require,import/no-dynamic-require
        const themeData = require(`./themes/${this.theme}/data.json`);
        return themeData[this.$lang] || themeData.ru || {};
      } catch {
        return null;
      }
    },
    noFooter() {
      return !this.data;
    },
    copyright() {
      return (this.data.copyright || '')
        .replace('__YEAR__', this.year.toString());
    },
    socials() {
      return this.data.socials || [];
    },
    links() {
      return this.data.links || [];
    },
    hideLang() {
      return this.data.hideLang || false;
    },
  },
  methods: {
    changeLang(lang) {
      setCulture(lang, this.themeUrls.Events);
    },
    openFeedbackForm() {
      if (window.Userback) {
        window.Userback.open();
      }
    },
  },
};
</script>

<style lang="scss" scoped>
  @import "./Footer";
</style>
