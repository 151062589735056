function formatPaceText(valueText, className) {
  if (!valueText) {
    return '';
  }

  const value = valueText.toString().replace('.', ',');
  const i = value.indexOf(' ');

  if (i === -1) {
    return value;
  }

  return `${value.slice(0, i)}<span class="${className || 'text-xs'}">${value.slice(i)}</span>`;
}

export {
  // eslint-disable-next-line import/prefer-default-export
  formatPaceText,
};
