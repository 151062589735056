<template>
  <div
    class="tabs"
    :class="[
      variant && `tabs_${variant}`,
      centered && 'tabs_centered'
    ]"
  >
    <div class="tabs__container">
      <div
        class="tabs__item"
        :class="{
          'tabs__item_active': active === id || $route.name === (to && to.name)
        }"
        v-for="{ id, name, to } in items"
        :key="id"
        :is="to ? 'router-link' : 'div'"
        :to="to"
        @click.native="changeTab(id)"
      >
        {{ name }}
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: 'Tabs',
  props: {
    items: Array,
    active: String,
    variant: String,
    centered: Boolean,
  },
  methods: {
    changeTab(id) {
      if (this.active !== id) {
        this.$emit('change', id);
      }
    },
  },
};
</script>

<style lang="scss" scoped>
@import "./Tabs";
</style>
